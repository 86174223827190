import { CloseOutlined, EditOutlined, RollbackOutlined, CheckOutlined } from "@ant-design/icons";
import { Button, Col, Input, message, Row, Select, Space, Skeleton, Card } from "antd";
import moment from "moment";
import 'moment/locale/vi';
import { useEffect, useRef, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux";
import Item_Ads from "../../../components/Item_Ads";
import { ContentDatePicker, ContentItemCheckbox, ContentItemSearch, ContentRadio, ContentSelect, ContentSlider, SelectHeader } from "../../../components/Select_Header";
import { dateFormat, numberFormat, removeNullValues, smoothScrollTo, uuidv4Short } from "../../../utils/help";
import Panel_Filter from "../../../components/Panel_Filter";
import { get_ads, save_go_back } from "../../../modules/ads/actions";
import InfiniteScroll from "react-infinite-scroll-component";
import { config_filter } from "../../../modules/user/actions";


import Modal_Filter from "./Modal"
import { setting_get } from "../../../modules/setting/actions";
Object.defineProperty(Array.prototype, 'chunk_inefficient', {
   value: function (chunkSize) {
      var array = this;
      return [].concat.apply([],
         array.map(function (elem, i) {
            return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
         })
      );
   }
});

const Victims = (props) => {

   const dispatch = useDispatch();
   const _adsList = useSelector(({ ads }) => ads.adsList);
   const _saveList = useSelector(({ ads }) => ads.saveList);

   const _me = useSelector(({ user }) => user.me);
   const user = _me.user
   const _settingList = useSelector(({ setting }) => setting.settingList);
   const _configFilter = useSelector(({ user }) => user.configFilter);



   let search_data = {
      orderBy: [
         {
            value: "created_on_desc",
            label: "Date (recent on top)",
            ig: true,
         },
         {
            value: "created_on_asc",
            label: "Date (oldest on top)",
            ig: true,
         },
         { value: "total_likes", label: "Likes", ig: true },
         { value: "total_loves", label: "Love" },
         { value: "total_hahas", label: "Haha" },
         { value: "total_wows", label: "Wow" },
         { value: "total_sads", label: "Sad" },
         { value: "total_angrys", label: "Angry" },
         { value: "total_shares", label: "Shares" },
         { value: "run_time", label: "Longest running", ig: true },
      ],
      searchType: [{
         label: "Ad Text",
         value: "texts"
      }, {
         label: "Comments",
         value: "comments"
      }, {
         label: "Advertiser Name",
         value: "advertisers"
      }, {
         label: "URL",
         value: "urls"
      }, {
         label: "Landing Page URL",
         value: "lp_urls",
      }, {
         label: "Landing Page Text",
         value: "page_text",
      }],
      siteType: ["Facebook", "Instagram"],
      gender: ["Male", "Female"],
      ages: [18, 65],
      dailyLikes: [0, 1000],
      totalLikes: [0, 100000],
      mediaType: ["Video", "Photo"],
      createdBetween: [null, null],
      seenBetween: [null, null],
      affId: null,
      offerId: null,
      userId: null,
      username: null,
      lang: _settingList.data.length ? _settingList.data.find((item) => item.key == "languages").value : [],
      buttons: _settingList.data.length ? _settingList.data.find((item) => item.key == "buttons").value : [],
      tech: _settingList.data.length ? _settingList.data.find((item) => item.key == "technologies").value : [],
      affNetwork: _settingList.data.length ? _settingList.data.find((item) => item.key == "affNetworks").value : [],
      countries: _settingList.data.length ? _settingList.data.find((item) => item.key == "countries").value : [],
   }
   const initialState = {
      search: {
         orderBy: null,
         searchType: search_data.searchType[0],
         siteType: null,
         gender: null,
         ages: null,
         dailyLikes: null,
         totalLikes: null,
         mediaType: null,
         createdBetween: search_data.createdBetween,
         seenBetween: search_data.seenBetween,
         affNetwork: null,
         tech: null,
         affId: null,
         offerId: null,
         userId: null,
         username: null,
         lang: null,
         buttons: null,
         countries: null,
         searches: [],
      },
      current_search: null,
      isOpen: null,
      tempAffId: null,
      tempOfferId: null,
      tempUserId: null,
      tempUsername: null,
      tempSearchType: null,
      isClearAll: false,
      start: 0,
      isBlank: false,
      firstAdsItem: [],
      secondAdsItem: [],
      page: 0,
      ads_item: [],
      isMore: true,
      isModalOpenSave: false,
      isModalOpenEdit: false,
      haveFilter: false,
      save_filter: user?.save_filter || [],
      save_filter_select: null,
      save_filter_name: null,
      save_filter_delete: null
   }
   const [state, setState] = useState(initialState);

   useEffect(() => {

      let search = JSON.parse(localStorage.getItem("search"));

      if (_saveList && _saveList.data && _saveList.data.length) {
         // console.log("BACK", _saveList)
         let query = render_query(search)
         setState(state => ({ ...state, start: 0, ads_item: _saveList.data, page: _saveList.page, search: search, current_search: query }))
         const savedScrollPosition = localStorage.getItem('savedScrollPosition');
         if (savedScrollPosition) {
            smoothScrollTo(savedScrollPosition)
         }
      }
      else {
         localStorage.setItem('savedScrollPosition', 0);
         setState(state => ({ ...state, start: state.start + 1, page: 0, search: { ...initialState.search, ...search } }))

      }
   }, [])

   // console.log("STATE", state)
   const render_query = (search) => {
      let query = {
         orderBy: search.orderBy?.value || null,
         siteType: search.siteType || null,
         gender: search.gender || null,
         ages: search.ages || null,
         dailyLikes: search.dailyLikes,
         totalLikes: search.totalLikes,
         mediaType: search.mediaType,
         createdBetween: search.createdBetween,
         seenBetween: search.seenBetween,
         affNetwork: search.affNetwork?.value || null,
         tech: search.tech?.map(e => e.value) || [],
         affId: search.affId,
         offerId: search.offerId,
         userId: search.userId,
         username: search.username,
         lang: search.lang?.value || null,
         buttons: search.buttons?.map(e => e.value) || [],
         countries: search.countries?.map(e => e.value) || [],
         searches: search.searches,
      }
      return query
   }
   const get_ads_function = (reset, force) => {

      let { search } = state
      localStorage.setItem("search", JSON.stringify({ ...state.search }));
      let query = render_query(search)
      let isSame = JSON.stringify(state.current_search) == JSON.stringify(query);
      let isHaveFilter = JSON.stringify(state.search) == JSON.stringify(initialState.search) ? false : true;
      let page = reset ? 0 : isSame ? (state.page + 1) : 0
      // console.log(page, isSame)
      if (reset)
         setState(state => ({
            ...state,
            haveFilter: false,
            isClearAll: false,
            page: 0,
            ads_item: [],
            current_search: { ...query },
            // save_filter_select: null
         }))
      else {
         setState(state => ({
            ...state,
            haveFilter: isHaveFilter,
            isClearAll: state.isClearAll ? !isHaveFilter : false,
            // save_filter_select: !isSame ? null : state.save_filter_select,
            page,
            current_search: { ...query }
         }))
      }
      dispatch(get_ads({
         ...query,
         page
      }))

   }
   useEffect(() => {
      if (state.start !== 0)
         get_ads_function(false)

   }, [state.start])



   useEffect(() => {
      console.log("_adsList",_adsList)
      if (_adsList.status) {
         setState(state => ({
            ...state,
            isMore: _adsList.data.length >= 10 ? true : false,
            ads_item: _adsList.data,
         }))
         let payload = {
            data: _adsList.data,
            page: state.page,
         }
         dispatch(save_go_back(payload))
      }
      else if (_adsList.status == false && _adsList.msg !== null) {
         message.warning(_adsList.msg)
      }
   }, [_adsList])

   useEffect(() => {
      if (_configFilter.status) {
         setState(state => ({
            ...state, save_filter: _configFilter.data.type == "add" ? [...state.save_filter, _configFilter.data] :
               state.save_filter.filter(item => item.id !== _configFilter.data.id)
         }))
         message.success(_configFilter.msg)
      }
      else if (_configFilter.status == false && _configFilter.msg !== null) {
         message.warning(_configFilter.msg)
      }
   }, [_configFilter])

   useEffect(() => {
      if (state.save_filter_delete && state.save_filter_delete?.id) {
         const { id, filter, name } = state.save_filter_delete
         handle_config_filter("delete", id, name, filter)
      }
   }, [state.save_filter_delete])

   function getStringType(str) {
      try {
         const words = str.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
         const capitalizedWords = words.split(" ").map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
         });
         return capitalizedWords.join(' ');

      } catch (error) {
         return str
      }
   }
   const handle_config_filter = (type, id, name, filter) => {
      if (!name)
         return message.warning("Please input filter name")
      if (!type || !id || !filter)
         return message.warning("Error. Contact admin")
      dispatch(config_filter({
         id,
         type,
         name: name,
         filter: removeNullValues(filter)
      }))
      setState(state => ({ ...state, isModalOpenSave: false }))
   }
   const handle_set_filter = (option) => {
      if (typeof option == "object") {
         setState(state => ({
            ...state,
            start: state.start + 1,
            isClearAll: false,
            search: { ...initialState.search, ...option.search },
         }))
      }
      else {
         message.warning("Cannot set filter")
      }

   }

   const Header = () => {
      return <div style={{
         width: "100%",
     
         WebkitBoxPack: "center",
         justifyContent: "center",
         backgroundImage: "url(https://app.adspy.com/panel-bg.70f6a07b78859be62492.jpg)",
        
      }}


      >
         <div style={{
            width: "100%",
            maxWidth: "1100px",
            margin: "0 auto",
            textAlign: "center",
            marginTop: 80
         }}>

            <div style={{
               width: "100%",
               maxWidth: "600px",
               margin: "0 auto",

            }}>
               <h1 style={{paddingTop:60}} className="headerTitle">Search for ads</h1>
               <Row>
                  <Col span={isMobile ? 24 : 6} className="pd10S">
                     <SelectHeader
                        open={state.isOpen == "1"}
                        onClose={() => setState(state => ({ ...state, isOpen: null }))}

                        onChangeStatus={() => setState({ ...state, isOpen: state.isOpen == "1" ? null : "1" })}
                        content={
                           <ContentSelect
                              item={search_data.searchType}
                              value={state.search.searchType}
                              onChange={(e) => {

                                 setState({ ...state, isOpen: null, tempSearchType: null, search: { ...state.search, searchType: e } })
                              }}

                              onReset={() => setState({ ...state, search: { ...state.search, searchType: initialState.search.searchType } })}
                           />
                        }
                        text={state.search.searchType.label}
                     />
                  </Col>
                  <Col span={isMobile ? 24 : 10} className="pd10S" style={{ marginTop: isMobile ? "-15px" : 0 }}>
                     <Input className="inputClass" value={state.tempSearchType} style={{ width: "100%", color: "white", textAlign: isMobile ? "center" : "left" }}

                        onChange={(e) => setState(state => ({ ...state, tempSearchType: e.target.value }))}
                        placeholder="Enter keywords"></Input>
                  </Col>
                  <Col span={isMobile ? 24 : 8} className="pd10S">
                     <Space direction="horizontal" style={{ width: '100%', marginTop: isMobile ? "-50px" : 10, justifyContent: "center" }} >
                        <Button disabled={!state.tempSearchType ? true : false}
                           onClick={() => {
                              let newSearch = {
                                 label: state?.search?.searchType?.label,
                                 type: state?.search?.searchType?.value,
                                 value: state?.tempSearchType,
                                 current: true
                              }

                              let checkExist = state.search.searches.find(e => e.type == newSearch.type && e.current === true)
                              if (checkExist) {
                                 let updateSearches = state.search.searches.map(e => {
                                    if (e.type == newSearch.type && e.current == true) // Tìm giống type và có current true
                                    {
                                       return { ...e, value: newSearch.value } // Thay đổi giá trị
                                    }
                                    else {
                                       return { ...e }
                                    }

                                 })
                                 setState(state => ({ ...state, start: state.start += 1, search: { ...state.search, searches: updateSearches } }))
                              }
                              else {
                                 setState(state => ({ ...state, start: state.start += 1, search: { ...state.search, searches: [...state.search.searches, newSearch] } }))
                              }
                           }}
                           className="btnDark">Preview</Button>
                        <Button
                           onClick={() => {
                              let newSearch = {
                                 label: state?.search?.searchType?.label,
                                 type: state?.search?.searchType?.value,
                                 value: state?.tempSearchType,
                                 locked: false
                              }
                              let checkExist = state.search.searches.find(e => e.type == newSearch.type && e.value == newSearch.value)
                              if (checkExist) {
                                 let updateSearches = state.search.searches.map(e => {
                                    if (e.type == newSearch.type && e.value == newSearch.value) {
                                       if (e?.current)
                                          delete e.current
                                       return { ...e, locked: false }
                                    }
                                    else {
                                       return { ...e }
                                    }

                                 })
                                 updateSearches = Array.from(new Set(updateSearches.map(JSON.stringify))).map(JSON.parse);
                                 setState(state => ({ ...state, start: state.start += 1, tempSearchType: null, search: { ...state.search, searches: updateSearches } }))
                              }
                              else {
                                 setState(state => ({ ...state, start: state.start += 1, tempSearchType: null, search: { ...state.search, searches: [...state.search.searches, newSearch] } }))
                              }
                           }}
                           disabled={!state.tempSearchType ? true : false} className="btnDark btnLight">Add to Filters</Button>
                     </Space>
                  </Col>
               </Row>
            </div>

            {/* Search Filter */}
            <div>
               <Row>
                  <Col className="headerCol">
                     <SelectHeader
                        onClose={() => setState(state => ({ ...state, isOpen: null }))}
                        open={state.isOpen == "2"}
                        onChangeStatus={() => setState({ ...state, isOpen: state.isOpen == "2" ? null : "2" })}
                        content={
                           <ContentRadio
                              item={search_data.siteType}
                              value={state.search.siteType}
                              onChange={(e) => {
                                 setState({ ...state, start: state.start += 1, search: { ...state.search, siteType: e } })
                              }}
                              onReset={() => setState(state => ({ ...state, start: state.start += 1, search: { ...state.search, siteType: initialState.search.siteType } }))}
                           />
                        }
                        text={"Site type"}
                     />
                  </Col>
                  <Col className="headerCol">
                     <SelectHeader
                        open={state.isOpen == "3"}
                        onChangeStatus={() => setState({ ...state, isOpen: state.isOpen == "3" ? null : "3" })}
                        onClose={() => setState(state => ({ ...state, isOpen: null }))}
                        content={
                           <ContentRadio
                              item={search_data.gender}
                              value={state.search.gender}
                              onChange={(e) => {
                                 setState({ ...state, start: state.start += 1, search: { ...state.search, gender: e } })
                              }}
                              onReset={() => setState(state => ({ ...state, start: state.start += 1, search: { ...state.search, gender: initialState.search.gender } }))}
                           />
                        }
                        text={"Gender"}
                     />
                  </Col>
                  <Col className="headerCol">
                     <SelectHeader
                        onClose={() => setState(state => ({ ...state, isOpen: null }))}

                        open={state.isOpen == "4"}
                        onChangeStatus={() => setState({ ...state, isOpen: state.isOpen == "4" ? null : "4" })}
                        content={
                           <ContentSlider
                              min={search_data.ages[0]}
                              max={search_data.ages[1]}
                              value={state.search.ages || search_data.ages}
                              defaultValue={search_data.ages}
                              onChange={(e) => {
                                 if (e[0] == search_data.ages[0] && e[1] == search_data.ages[1])
                                    setState(state => ({ ...state, start: state.start += 1, search: { ...state.search, ages: initialState.search.ages } }))
                                 else {
                                    setState({ ...state, start: state.start += 1, search: { ...state.search, ages: e } })
                                 }
                              }}
                              onReset={() => setState(state => ({ ...state, start: state.start += 1, search: { ...state.search, ages: null } }))}
                           />

                        } text="Ages" />
                  </Col>
                  <Col className="headerCol">
                     <SelectHeader
                        onClose={() => setState(state => ({ ...state, isOpen: null }))}

                        open={state.isOpen == "5"}
                        onChangeStatus={() => setState({ ...state, isOpen: state.isOpen == "5" ? null : "5" })}
                        content={
                           <ContentSlider
                              min={search_data.dailyLikes[0]}
                              max={search_data.dailyLikes[1]}
                              defaultValue={search_data.dailyLikes}
                              value={state.search.dailyLikes || search_data.dailyLikes}
                              onChange={(e) => {

                                 if (e[0] == search_data.dailyLikes[0] && e[1] == search_data.dailyLikes[1])
                                    setState(state => ({ ...state, start: state.start += 1, search: { ...state.search, dailyLikes: initialState.search.dailyLikes } }))
                                 else {
                                    setState({ ...state, start: state.start += 1, search: { ...state.search, dailyLikes: e } })
                                 }
                              }}
                              onReset={() => setState(state => ({ ...state, start: state.start += 1, search: { ...state.search, dailyLikes: initialState.search.dailyLikes } }))}
                           />

                        } text="Daily likes" />
                  </Col>
                  <Col className="headerCol">
                     <SelectHeader
                        onClose={() => setState(state => ({ ...state, isOpen: null }))}

                        open={state.isOpen == "6"}
                        onChangeStatus={() => setState({ ...state, isOpen: state.isOpen == "6" ? null : "6" })}
                        content={
                           <ContentSlider
                              min={search_data.totalLikes[0]}
                              max={search_data.totalLikes[1]}
                              defaultValue={search_data.totalLikes}
                              value={state.search.totalLikes || search_data.totalLikes}
                              onChange={(e) => {

                                 if (e[0] == search_data.totalLikes[0] && e[1] == search_data.totalLikes[1])
                                    setState(state => ({ ...state, start: state.start += 1, search: { ...state.search, totalLikes: initialState.search.totalLikes } }))
                                 else {
                                    setState({ ...state, start: state.start += 1, search: { ...state.search, totalLikes: e } })
                                 }
                              }}
                              onReset={() => setState(state => ({ ...state, start: state.start += 1, search: { ...state.search, totalLikes: initialState.search.totalLikes } }))}
                           />

                        } text="Total likes" />
                  </Col>
                  <Col className="headerCol">
                     <SelectHeader
                        onClose={() => setState(state => ({ ...state, isOpen: null }))}

                        open={state.isOpen == "7"}
                        onChangeStatus={() => setState({ ...state, isOpen: state.isOpen == "7" ? null : "7" })}
                        content={
                           <ContentRadio
                              item={search_data.mediaType}
                              value={state.search.mediaType}
                              onChange={(e) => {
                                 setState({ ...state, start: state.start += 1, search: { ...state.search, mediaType: e } })
                              }}
                              onReset={() => setState(state => ({ ...state, start: state.start += 1, search: { ...state.search, mediaType: initialState.search.mediaType } }))}
                           />
                        } text="Media type" />
                  </Col>
                  <Col className="headerCol">
                     <SelectHeader
                        onClose={() => setState(state => ({ ...state, isOpen: null }))}

                        open={state.isOpen == "8"}
                        onChangeStatus={() => setState({ ...state, isOpen: state.isOpen == "8" ? null : "8" })}
                        content={
                           <ContentDatePicker
                              value={state.search.createdBetween}
                              onChange={(startDate, endDate) => {

                                 setState(state => ({
                                    ...state, start: state.start += 1, search: {
                                       ...state.search,
                                       createdBetween: [startDate ? dateFormat(startDate) : null, endDate ? dateFormat(endDate) : null]
                                    }
                                 }))
                              }}

                              onReset={() => setState(state => ({ ...state, start: state.start += 1, search: { ...state.search, createdBetween: initialState.search.createdBetween } }))}
                           />
                        } text="Created Between" />

                  </Col>
                  <Col className="headerCol">
                     <SelectHeader
                        onClose={() => setState(state => ({ ...state, isOpen: null }))}

                        open={state.isOpen == "9"}
                        onChangeStatus={() => setState({ ...state, isOpen: state.isOpen == "9" ? null : "9" })}
                        content={
                           <ContentDatePicker
                              value={state.search.seenBetween}
                              onChange={(startDate, endDate) => {
                                 setState(state => ({ ...state, start: state.start += 1, search: { ...state.search, seenBetween: [dateFormat(startDate), dateFormat(endDate)] } }))
                              }}

                              onReset={() => setState(state => ({ ...state, start: state.start += 1, search: { ...state.search, seenBetween: initialState.search.seenBetween } }))}
                           />
                        } text="Seen Between" />

                  </Col>
                  <Col className="headerCol" >
                     <SelectHeader
                        onClose={() => setState(state => ({ ...state, isOpen: null }))}

                        open={state.isOpen == "10"}
                        onChangeStatus={() => setState({ ...state, isOpen: state.isOpen == "10" ? null : "10" })}
                        content={
                           <ContentItemSearch
                              item={search_data.affNetwork}
                              value={state.search.affNetwork}
                              onChange={(e) => {
                                 setState({ ...state, start: state.start += 1, search: { ...state.search, affNetwork: e } })
                              }}
                              onReset={() => {
                                 setState(state => ({ ...state, start: state.start += 1, search: { ...state.search, affNetwork: initialState.search.affNetwork } }))
                              }}
                           />
                        }
                        text="Networks"
                     />

                  </Col>

                  <Col className="headerCol">
                     <SelectHeader
                        onClose={() => setState(state => ({ ...state, isOpen: null }))}

                        open={state.isOpen == "11"}
                        onChangeStatus={() => setState({ ...state, isOpen: state.isOpen == "11" ? null : "11" })}
                        content={
                           <div style={{ width: 200, height: 280, padding: "20px 10px", textAlign: "left" }}>
                              <div>
                                 <div className="itemInputTitle">Affiliate ID</div>
                                 <Input className="itemInput" value={state.tempAffId}
                                    onChange={(e) => setState({ ...state, tempAffId: e.target.value })}
                                 />
                                 <div style={{ marginTop: 10, display: "flex", justifyContent: "space-between" }}>
                                    <Button onClick={() => {
                                       setState({ ...state, start: state.start += 1, search: { ...state.search, affId: state.tempAffId } })
                                    }} className="btnDark"  >Go</Button>
                                    <Button onClick={() => {
                                       setState({ ...state, start: state.start += 1, tempAffId: null, search: { ...state.search, affId: initialState.search.affId } })
                                    }} className="btnDark" icon={<CloseOutlined />}>Reset</Button>
                                 </div>
                              </div>
                              <div style={{ marginTop: 30 }}>
                                 <div className="itemInputTitle">Offer ID</div>
                                 <Input key={Math.random}

                                    defaultValue={state.tempOfferId} className="itemInput" value={state.tempOfferId}
                                    onChange={(e) => {
                                       setState({ ...state, tempOfferId: e.target.value })
                                    }}
                                 />
                                 <div style={{ marginTop: 10, display: "flex", justifyContent: "space-between" }}>
                                    <Button onClick={() => {
                                       setState({ ...state, start: state.start += 1, search: { ...state.search, offerId: state.tempOfferId } })
                                    }} className="btnDark"  >Go</Button>
                                    <Button onClick={() => {
                                       setState({ ...state, start: state.start += 1, tempOfferId: null, search: { ...state.search, offerId: initialState.search.offerId } })
                                    }} className="btnDark" icon={<CloseOutlined />}>Reset</Button>
                                 </div>
                              </div>
                           </div >
                        }
                        text="Affiliate"
                     />
                  </Col>
                  <Col className="headerCol">
                     <SelectHeader
                        onClose={() => setState(state => ({ ...state, isOpen: null }))}

                        open={state.isOpen == "12"}
                        onChangeStatus={() => setState({ ...state, isOpen: state.isOpen == "12" ? null : "12" })}
                        content={
                           <div style={{ width: 200, height: 280, padding: "20px 10px", textAlign: "left" }}>
                              <div>
                                 <div className="itemInputTitle">Advertiser User ID</div>
                                 <Input className="itemInput" value={state.tempUserId}
                                    onChange={(e) => setState({ ...state, tempUserId: e.target.value })}
                                 />
                                 <div style={{ marginTop: 10, display: "flex", justifyContent: "space-between" }}>
                                    <Button onClick={() => {
                                       setState({ ...state, start: state.start += 1, search: { ...state.search, userId: state.tempUserId } })
                                    }} className="btnDark"  >Go</Button>
                                    <Button onClick={() => {
                                       setState({ ...state, start: state.start += 1, tempUserId: null, search: { ...state.search, userId: initialState.search.userId } })
                                    }} className="btnDark" icon={<CloseOutlined />}>Reset</Button>
                                 </div>
                              </div>
                              <div style={{ marginTop: 30 }}>
                                 <div className="itemInputTitle">Advertiser Username</div>
                                 <Input key={Math.random}

                                    defaultValue={state.tempUsername} className="itemInput" value={state.tempUsername}
                                    onChange={(e) => {
                                       setState({ ...state, tempUsername: e.target.value })
                                    }}
                                 />
                                 <div style={{ marginTop: 10, display: "flex", justifyContent: "space-between" }}>
                                    <Button onClick={() => {
                                       setState({ ...state, start: state.start += 1, search: { ...state.search, username: state.tempUsername } })
                                    }} className="btnDark"  >Go</Button>
                                    <Button onClick={() => {
                                       setState({ ...state, start: state.start += 1, tempUsername: null, search: { ...state.search, username: initialState.search.username } })
                                    }} className="btnDark" icon={<CloseOutlined />}>Reset</Button>
                                 </div>
                              </div>
                           </div >
                        }
                        text="Advertiser"
                     />
                  </Col>
                  <Col className="headerCol">
                     <SelectHeader
                        onClose={() => setState(state => ({ ...state, isOpen: null }))}

                        open={state.isOpen == "13"}
                        onChangeStatus={() => setState({ ...state, isOpen: state.isOpen == "13" ? null : "13" })}
                        content={
                           <ContentItemCheckbox
                              item={search_data.tech}
                              value={state.search.tech || []}
                              onChange={(e) => {
                                 const checkedOptions = search_data.tech.filter(option => e.includes(option.value));

                                 setState({ ...state, start: state.start += 1, search: { ...state.search, tech: checkedOptions.length ? checkedOptions : initialState.search.tech } })
                              }}

                              onReset={() => setState({ ...state, start: state.start += 1, search: { ...state.search, tech: initialState.search.tech } })}
                           />
                        }
                        text="Technologies"
                     />
                  </Col>
                  <Col className="headerCol">
                     <SelectHeader
                        onClose={() => setState(state => ({ ...state, isOpen: null }))}

                        open={state.isOpen == "14"}
                        onChangeStatus={() => setState({ ...state, isOpen: state.isOpen == "14" ? null : "14" })}
                        content={
                           <ContentItemCheckbox
                              item={search_data.countries}
                              value={state.search.countries || []}
                              onChange={(e) => {
                                 const checkedOptions = search_data.countries.filter(option => e.includes(option.value));

                                 setState({ ...state, start: state.start += 1, search: { ...state.search, countries: checkedOptions.length ? checkedOptions : initialState.search.countries } })
                              }}

                              onReset={() => setState({ ...state, start: state.start += 1, search: { ...state.search, countries: initialState.search.countries } })}
                           />
                        }
                        text="Countries"
                     />
                  </Col>
                  <Col className="headerCol" >
                     <SelectHeader
                        onClose={() => setState(state => ({ ...state, isOpen: null }))}

                        open={state.isOpen == "15"}
                        onChangeStatus={() => setState({ ...state, isOpen: state.isOpen == "15" ? null : "15" })}
                        content={
                           <ContentItemSearch
                              item={search_data.lang}
                              value={state.search.lang}
                              onChange={(e) => {
                                 setState({ ...state, start: state.start += 1, search: { ...state.search, lang: e } })
                              }}
                              onReset={() => setState({ ...state, start: state.start += 1, search: { ...state.search, lang: initialState.search.lang } })}
                           />
                        }
                        text="Language"
                     />

                  </Col>
                  <Col className="headerCol">
                     <SelectHeader
                        onClose={() => setState(state => ({ ...state, isOpen: null }))}
                        open={state.isOpen == "16"}
                        onChangeStatus={() => setState({ ...state, isOpen: state.isOpen == "16" ? null : "16" })}
                        content={
                           <ContentItemCheckbox
                              item={search_data.buttons}
                              value={state.search.buttons || []}
                              onChange={(e) => {
                                 const checkedOptions = search_data.buttons.filter(option => e.includes(option.value));
                                 setState({
                                    ...state, start: state.start += 1, search: {
                                       ...state.search,
                                       buttons: checkedOptions.length ? checkedOptions : null
                                    }
                                 })
                              }}

                              onReset={() => setState({ ...state, start: state.start += 1, search: { ...state.search, buttons: initialState.search.buttons } })}
                           />
                        }
                        text="Buttons"
                     />
                  </Col>
               </Row>

            </div>

            <div style={{ padding: "0px 30px" }}>
               <div style={{ textAlign: isMobile ? "center" : "left" }}>
                  <Button style={{ margin: 5 }} disabled={!state.haveFilter} onClick={() => {
                     localStorage.setItem("old_search", JSON.stringify(state.search))
                     setState({
                        ...initialState,
                        start: state.start + 1,
                        isClearAll: true,
                        haveFilter: false
                     })

                  }} icon={<CloseOutlined />} className="btnDark">Clear all</Button>

                  {state.isClearAll == true ? <Button style={{ margin: 5 }} onClick={() => {
                     let search = localStorage.getItem("old_search")
                     let search_parse = JSON.parse(search)
                     if (search && search_parse) {
                        setState(state => ({ ...state, search: search_parse, isClearAll: false, haveFilter: true }))
                     }
                  }} icon={<RollbackOutlined />} className="btnDark">Undo</Button> : null}
               </div>
               {/* Delete filter keyworld */}
               <div style={{ textAlign: isMobile ? "center" : "left" }}>
                  <Lock_Filter haveDel={true} haveLock={true} data={{ ...state.search }} />
               </div>
               {/* Delete filter */}
               <div style={{ textAlign: isMobile ? "center" : "left", paddingBottom: 20 }}>
                  <Button_Filter haveDel={true} data={{ ...state.search }} />
               </div>
            </div>
         </div>
      </div>
   }
   const Lock_Filter = (props) => {
      return props.data?.searches && props.data?.searches?.length ?
         props.data.searches.map((item, index) => {
            if (typeof item.locked == "boolean") {
               return (
                  <>
                     <Panel_Filter
                        key={uuidv4Short()}
                        haveLock={props.haveLock}
                        haveDel={props.haveDel}
                        label={item.label}
                        locked={item.locked}
                        value={item.value}
                        onLockChange={() => {
                           let updateSearches = props.data.searches.map((e) =>
                              typeof e.current == "boolean" || (e.type == item.type && e.value != item.value) || (e.type != item.type) ?
                                 { ...e } :
                                 { ...e, locked: !e.locked }
                           )
                           setState(state => ({ ...state, start: state.start += 1, search: { ...props.data, searches: [...updateSearches] } }))
                        }}
                        onDelete={() => {
                           let newSearches = props.data.searches.filter((e) => typeof e.current == "boolean"
                              || (e.type == item.type && e.value != item.value)
                              || (e.type != item.type)
                           )
                           setState(state => ({ ...state, start: state.start += 1, search: { ...props.data, searches: [...newSearches] } }))
                        }}
                     />
                     {props.break ? <br /> : null}
                  </>
               )
            }

         })
         : <></>

   }
   const Button_Filter = (props) => {
      return props.data && Object.keys(props.data).length ?
         Object.keys(props.data).map((key, index) => {
            let value = props.data[key];
            if (value && key !== "searchType" && key !== "searches")
               if ((key == "createdBetween" || key == "seenBetween") && (!moment(value[0]).isValid() || !moment(value[1]).isValid())) {
                  return null
               }
               else if (Array.isArray(value) && value.length == 0) {
                  return null
               }
               else {
                  return (
                     <Panel_Filter
                        key={uuidv4Short()}
                        haveLock={false}
                        haveDel={props.haveDel}
                        label={getStringType(key)}
                        value={
                           `${key == "createdBetween" || key == "seenBetween" ? `from ${value[0]} to ${value[1]}`
                              : key == "ages" || key == "dailyLikes" || key == "totalLikes" ? `from ${numberFormat(value[0])} to ${numberFormat(value[1])}`
                                 : key == "tech" || key == "countries" || key == "buttons" ? value.map(it => it.label).join(",")
                                    : key == "affNetwork" || key == "lang" || key == "orderBy" ? value.label
                                       : value
                           }`}

                        onDelete={() => {
                           setState({ ...state, start: state.start += 1, search: { ...props.data, [key]: initialState.search[key] } })
                        }}

                     />
                  )
               }
         })
         : <></>

   }
   const Loading = () => {

      return (
         <Row>
            <Col span={isMobile ? 24 : 12} style={{ paddingRight: 10 }}>
               <Card style={{ minHeight: 300 }}>
                  <Skeleton paragraph active />
               </Card>
            </Col>
            {!isMobile ? <Col span={isMobile ? 24 : 12} style={{ paddingRight: 10 }}>
               <Card style={{ minHeight: 300 }}>
                  <Skeleton paragraph active />
               </Card>
            </Col> : null}

         </Row>

      )
   }
   const Content = () =>
   (
      <>
         <div style={{
            width: "100%",
            maxWidth: "1100px",
            margin: "0 auto",
         }}>
            {/* Home filter */}
            <div style={{ padding: "30px 10px", width: "100%" }}>
               <Row>
                  <Col span={12} style={{ textAlign: isMobile ? "center" : "left" }}>
                     <Select value={state.search?.orderBy?.value} className="selectEdit" style={{ width: 200 }} onChange={(e, b) => {

                        setState(state => ({ ...state, start: state.start += 1, search: { ...state.search, orderBy: { ...b, label: b.children } } }))
                     }} placeholder="Date (recent on top)" >
                        {search_data.orderBy.map(item => {
                           if (item && (state.search?.siteType !== "Instagram" || item?.ig)) {
                              // console.log(item)

                              return <Select.Option value={item.value} key={uuidv4Short()} >{item?.label}</Select.Option>


                           }
                        })}
                     </Select>
                  </Col>
                  <Col span={12} style={{ textAlign: isMobile ? "center" : "right" }}>
                     <Button className="btnDark" style={{ marginRight: 30 }}
                        onClick={() => setState(state => ({ ...state, isModalOpenSave: true }))}
                        disabled={!state.haveFilter}
                     >Save Current Search</Button>
                     <Select
                        // value={state.save_filter_select} 
                        className="selectEdit" style={{ width: 200 }} placeholder="Select Search" onChange={(key, option) => handle_set_filter(option)}>
                        {state?.save_filter && state?.save_filter?.length ?
                           state.save_filter.map(item => <Select.Option value={item.id} key={item.id} search={item.filter}>{item.name}</Select.Option>) : null
                        }
                     </Select>
                     <Button
                        onClick={() => setState(state => ({ ...state, isModalOpenEdit: true }))}
                        icon={<EditOutlined />} className="btnDark" style={{ marginLeft: 30 }} shape="circle"></Button>
                  </Col>

               </Row>

            </div>
            {/* Ads Item */}

            <InfiniteScroll
               dataLength={state.ads_item.length}
               next={() => {
                  setState(state => ({ ...state, start: state.start + 1 }))
               }}
               hasMore={state.isMore}

               // refreshFunction={() => {
               //    get_ads_function(true)
               // }}
               // pullDownToRefresh
               // pullDownToRefreshThreshold={50}
               // pullDownToRefreshContent={
               //    <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
               // }
               // releaseToRefreshContent={
               //    <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
               // }
               loader={<Loading />}
               endMessage={
                  <div style={{ textAlign: "center", marginTop: 20 }}>
                     <h1 style={{ fontSize: "2em" }}>No Results</h1>
                  </div>
               }
            >
               {state.ads_item && state.ads_item.length ?
                  <Row>

                     <Col span={isMobile ? 24 : 12} style={{ paddingRight: 5 }}>
                        {state.ads_item.map((item, index) => index % 2 === 0 ? <Item_Ads onClickHeader={(uid)=>{
                           setState({ ...state, start: 1, search: { ...initialState.search, userId: uid } })
                        }} key={uuidv4Short()} data={item} /> : null)}
                     </Col>


                     <Col span={isMobile ? 24 : 12} style={{ paddingRight: 5 }}>
                        {state.ads_item.map((item, index) => index % 2 !== 0 ? <Item_Ads key={uuidv4Short()} data={item} /> : null)}
                     </Col>

                  </Row> : null
               }
            </InfiniteScroll>
         </div>
         <Modal_Filter

            title="Save Search"
            content={
               <div>
                  <div style={{ width: 150, marginBottom: 10 }}>
                     <Input placeholder="Name" className="itemInput"
                        value={state.save_filter_name}
                        onChange={(e) => setState({ ...state, save_filter_name: e.target.value })}
                     /></div>
                  <div className="save_search">
                     <Lock_Filter haveDel={false} break={true} haveLock={false} data={{ ...state.search }} />
                     <Button_Filter haveDel={false} data={{ ...state.search }} />
                  </div>
               </div>
            }
            onOK={() => handle_config_filter("add", uuidv4Short(), state.save_filter_name, state.search)}
            open={state.isModalOpenSave}
            onCancel={() => setState(state => ({ ...state, isModalOpenSave: false }))}
         />
         <Modal_Filter
            size={"900px"}
            title="Manage Searches"
            content={
               <>
                  {state.save_filter && state.save_filter.length ?
                     <table className="table_save_filter" style={{ width: '100%' }}>
                        <thead>
                           <tr>
                              <th style={{ padding: "0px 0px 5px 0px" }}>Name</th>
                              <th>Parameters</th>
                              <th>Delete</th>
                           </tr>
                        </thead>
                        <tbody>

                           {state.save_filter.map(item => {
                              return (
                                 <tr className="ng-star-inserted" style={{}}>
                                    <td>{item.name}</td>
                                    <td style={{ wordBreak: 'break-all' }}>
                                       <div className="save_search_list">
                                          <Lock_Filter break={true} haveDel={false} haveLock={false} data={{ ...item.filter }} />
                                          <Button_Filter haveDel={false} data={{ ...item.filter }} />
                                       </div>
                                    </td>
                                    <td style={{ width: '30px' }}>
                                       <Button
                                          onClick={() => setState(state => ({ ...state, save_filter_delete: item }))}
                                          icon={<CloseOutlined />} className="btnDark" style={{ marginLeft: 30 }} shape="circle"></Button>
                                    </td>
                                 </tr>
                              )
                           })
                           }
                        </tbody>
                     </table> : <p style={{ textAlign: "center" }}>You don't have any saved searches</p>
                  }
               </>
            }

            open={state.isModalOpenEdit}
            onCancel={() => setState(state => ({ ...state, isModalOpenEdit: false }))}
            footer={false}
         />
      </>
   )
   return (
      <>
         {Header()}
         {Content()}
      </>
   )
}
export default Victims