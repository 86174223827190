// router.post("/user/register", users.register);
// router.post("/user/login", users.login);
// router.post("/user/me",auth, users.me);
// router.post("/user/logout", auth, users.logout);
// router.post("/user/create_payments_wildcore", auth, users.create_payments_wildcore);
// router.post("/user/get_payments_wildcore", auth, users.get_payments_wildcore);
// router.post("/user/approve_payments_wildcore", auth, users.approve_payments_wildcore);
// router.post("/user/create_payments_withdraw", auth, users.create_payments_withdraw);
// router.post("/user/get_payments_withdraw", auth, users.get_payments_withdraw);
// router.post("/user/approve_payments_withdraw", auth, users.approve_payments_withdraw);
// router.post("/user/get_users", auth, users.get_users);
// router.post("/user/get_server", auth, users.get_server);
// router.post("/user/register_ctv", auth, users.register_ctv);


export const REGISTER = "user/REGISTER";
export const REGISTER_SUCCESS = "user/REGISTER_SUCCESS";
export const REGISTER_ERROR= "user/REGISTER_ERROR";

export const LOGIN = "user/LOGIN";
export const LOGIN_SUCCESS = "user/LOGIN_SUCCESS";
export const LOGIN_ERROR = "user/LOGIN_ERROR";

export const ME = "user/ME";
export const ME_SUCCESS = "user/ME_SUCCESS";
export const ME_ERROR= "user/ME_ERROR";
export const SET_ME= "user/SET_ME";


export const LOG_OUT = "user/LOG_OUT";
export const LOG_OUT_SUCCESS = "user/LOG_OUT_SUCCESS";
export const LOG_OUT_ERROR = "user/LOG_OUT_ERROR";

export const CREATE_PAYMENT_WILDCORE = "user/CREATE_PAYMENT_WILDCORE";
export const CREATE_PAYMENT_WILDCORE_SUCCESS = "user/CREATE_PAYMENT_WILDCORE_SUCCESS";
export const CREATE_PAYMENT_WILDCORE_ERROR = "user/CREATE_PAYMENT_WILDCORE_ERROR";

export const GET_PAYMENT_WILDCORE = "user/GET_PAYMENT_WILDCORE";
export const GET_PAYMENT_WILDCORE_SUCCESS = "user/GET_PAYMENT_WILDCORE_SUCCESS";
export const GET_PAYMENT_WILDCORE_ERROR = "user/GET_PAYMENT_WILDCORE_ERROR";

export const APPROVE_PAYMENT_WILDCORE = "user/APPROVE_PAYMENT_WILDCORE";
export const APPROVE_PAYMENT_WILDCORE_SUCCESS = "user/APPROVE_PAYMENT_WILDCORE_SUCCESS";
export const APPROVE_PAYMENT_WILDCORE_ERROR = "user/APPROVE_PAYMENT_WILDCORE_ERROR";

export const DELETE_PAYMENT_WILDCORE = "user/DELETE_PAYMENT_WILDCORE";
export const DELETE_PAYMENT_WILDCORE_SUCCESS = "user/DELETE_PAYMENT_WILDCORE_SUCCESS";
export const DELETE_PAYMENT_WILDCORE_ERROR = "user/DELETE_PAYMENT_WILDCORE_ERROR";

export const CREATE_PAYMENT_WITHDRAW = "user/CREATE_PAYMENT_WITHDRAW";
export const CREATE_PAYMENT_WITHDRAW_SUCCESS = "user/CREATE_PAYMENT_WITHDRAW_SUCCESS";
export const CREATE_PAYMENT_WITHDRAW_ERROR = "user/CREATE_PAYMENT_WITHDRAW_ERROR";

export const GET_PAYMENT_WITHDRAW = "user/GET_PAYMENT_WITHDRAW";
export const GET_PAYMENT_WITHDRAW_SUCCESS = "user/GET_PAYMENT_WITHDRAW_SUCCESS";
export const GET_PAYMENT_WITHDRAW_ERROR = "user/GET_PAYMENT_WITHDRAW_ERROR";

export const APPROVE_PAYMENT_WITHDRAW = "user/APPROVE_PAYMENT_WITHDRAW";
export const APPROVE_PAYMENT_WITHDRAW_SUCCESS = "user/APPROVE_PAYMENT_WITHDRAW_SUCCESS";
export const APPROVE_PAYMENT_WITHDRAW_ERROR = "user/APPROVE_PAYMENT_WITHDRAW_ERROR";

export const DELETE_PAYMENT_WITHDRAW = "user/DELETE_PAYMENT_WITHDRAW";
export const DELETE_PAYMENT_WITHDRAW_SUCCESS = "user/DELETE_PAYMENT_WITHDRAW_SUCCESS";
export const DELETE_PAYMENT_WITHDRAW_ERROR = "user/DELETE_PAYMENT_WITHDRAW_ERROR";

export const DELETE = "user/DELETE";
export const DELETE_SUCCESS = "user/DELETE_SUCCESS";
export const DELETE_ERROR = "user/DELETE_ERROR";

export const GET_USER = "user/GET_USER";
export const GET_USER_SUCCESS = "user/GET_USER_SUCCESS";
export const GET_USER_ERROR= "user/GET_USER_ERROR";

export const REGISTER_CTV = "user/REGISTER_CTV";
export const REGISTER_CTV_SUCCESS = "user/REGISTER_CTV_SUCCESS";
export const REGISTER_CTV_ERROR= "user/REGISTER_CTV_ERROR";

export const RESET_USER = "user/RESET_USER";

export const UPDATE_USER = "user/UPDATE_USER";
export const UPDATE_USER_SUCCESS = "user/UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "user/UPDATE_USER_ERROR";

export const CHANGE_PASSWORD = "user/CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "user/CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR= "user/CHANGE_PASSWORD_ERROR";

export const UPDATE_ME = "user/UPDATE_ME";

export const CONFIG_FILTER = "user/CONFIG_FILTER";
export const CONFIG_FILTER_SUCCESS = "user/CONFIG_FILTER_SUCCESS";
export const CONFIG_FILTER_ERROR= "user/CONFIG_FILTER_ERROR";