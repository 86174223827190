import { AimOutlined, EyeInvisibleOutlined, EyeTwoTone, KeyOutlined, LogoutOutlined, MailOutlined, NumberOutlined, SendOutlined, TagOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Collapse, Input, Row, Select, Space } from "antd";
import Modal from "antd/lib/modal/Modal";
import 'moment/locale/vi';
import React from "react";
import { numberFormat } from "../../../utils/help";

const { Option } = Select;
const { Panel } = Collapse
const AccountView = (props) => {
   let state = props.state
   let isMobile = props.isMobile
   let update = props.update
   let create = props.create
   let setState = props.setState
   let select = state.select




   return (
      <Modal title={select._id !== null ? `Edit Account` : "Add Account"}
         open={state.isVisibleModal}
         maskClosable={false}
         destroyOnClose={true}
         onCancel={() => setState({ ...state, isVisibleModal: false })}
         width={isMobile ? "90%" : "40%"}
         footer={<Space direction="horizontal" style={{ width: '100%', justifyContent: isMobile ? "center" : "right" }} className="gx-w-100">
            <Button
               className='color1'
               loading={select._id !== null ? update.isLoading : create.isLoading}
               onClick={() => {
                  if (select._id !== null) {
                     props.handleUpdate()
                  }
                  else {
                     props.handleCreate()
                  }
               }} type="primary" icon={<SendOutlined />}>{select._id !== null ? "Update" : "Create & Login"}</Button>
            <Button icon={<LogoutOutlined />} onClick={() => setState({ ...state, isVisibleModal: false })} type="primary" className='color2'>Close</Button>
         </Space>}
      >

         <Row >
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
               {select._id ? <Input size="middle" placeholder="ID" prefix={<NumberOutlined />}
                  value={select._id}
                  addonAfter="ID"
                  className={!isMobile ? "pdR" : "pdB"}
                  disabled
               /> : <Input size="middle" placeholder="Paste account" prefix={<NumberOutlined />}
                  value={select.data}
                  addonAfter="email|pass"
                  className={!isMobile ? "pdR" : "pdB"}
                  onChange={(e) => {
                     let data = e.target.value.split("|")
                     if (data.length == 2)
                        setState({ ...state, select: { ...select, email: data[0], password: data[1], data: e.target.value } })
                     else setState({ ...state, select: { ...select, data: e.target.value } })

                  }}
               />}

            </Col>
            <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <Input size="middle" placeholder="Email" prefix={<MailOutlined />}
                  value={select.email}
                  addonAfter="Email"
                  onChange={(e) => setState({ ...state, select: { ...select, email: e.target.value } })}
                  // disabled={select._id}
               />
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
               <Input.Password size="middle" placeholder="Password" prefix={<KeyOutlined />}
                  value={select.password}
                  addonAfter="Password"
                  // disabled={select._id}
                  className={!isMobile ? "pdR" : "pdB"}
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}

                  onChange={(e) => setState({ ...state, select: { ...select, password: e.target.value } })}
               />
            </Col>


         </Row>

         <Row >
            <Col xl={24} lg={24} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <Input size="middle" placeholder="IP:PORT:USER:PASS" prefix={<AimOutlined />}
                  value={select.proxy}
                  addonAfter="Proxy"
                  onChange={(e) => setState({ ...state, select: { ...select, proxy: e.target.value } })}

               />
            </Col>

         </Row>
         {select._id ? <Row>
            <Col xl={8} lg={8} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <Input size="middle" placeholder="Views Period" prefix={<TagOutlined />}
                  value={numberFormat(select.viewsCurrentPeriod)}
                  addonAfter="Views Period"
                  disabled
               />
            </Col>
            
            <Col xl={8} lg={8} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <Input size="middle" placeholder="Views Left" prefix={<TagOutlined />}
                  value={numberFormat(select.viewsLeft)}
                  addonAfter="Views Left"
                  disabled

               />
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <Input size="middle" placeholder="Views Limit" prefix={<TagOutlined />}
                  value={numberFormat(select.viewsLimit)}
                  addonAfter="Views Limit"
                  disabled
               />
            </Col>
         </Row> : null}
         <Row >
         <Col xl={8} lg={8} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <p style={{ marginBottom: "4px" }}>Account Type</p>
               <Select
                  defaultValue={select.account_type}
                  value={select.account_type}
                  style={{ width: "100%", }} onChange={(value) => {
                     setState({ ...state, select: { ...select, account_type: value } })
                  }}
               >
                  <Option value={0}><Badge color={"blue"} text={"Free"} /></Option>
                  <Option value={1}><Badge color={"green"} text={"Premium"} /></Option>
               </Select>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <p style={{ marginBottom: "4px" }}>Live Account</p>
               <Select
                  defaultValue={select.live}
                  value={select.live}
                  style={{ width: "100%", }} onChange={(value) => {
                     setState({ ...state, select: { ...select, live: value } })
                  }}
               >
                  <Option value={true}><Badge color={"green"} text={"LIVE"} /></Option>
                  <Option value={false}><Badge color={"red"} text={"DIE"} /></Option>
               </Select>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <p style={{ marginBottom: "4px" }}>Status</p>
               <Select
                  defaultValue={select.status}
                  value={select.status}
                  style={{ width: "100%", }} onChange={(value) => {
                     setState({ ...state, select: { ...select, status: value } })
                  }}
               >
                  <Option value={true}><Badge color={"green"} text={"Active"} /></Option>
                  <Option value={false}><Badge color={"red"} text={"Deactive"} /></Option>
               </Select>
            </Col>
            
         </Row>
      </Modal>

   )
}
export default AccountView