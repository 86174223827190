import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../utils/asyncComponent";
import Ads from "./Ads/Ads";
import Ads_Details from "./Ads/Ads_Details";
const App = ({ match, history, location }) => {
    return (
        <Switch>
            <Route exact path={`/ads/:id`}  component={Ads_Details} />
            <Route exact path='/ads' component={Ads} />
            <Route exact path='/' component={Ads} />
        </Switch>

    );
}

export default App;
