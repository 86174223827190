import * as types from "./constants";
export function get_ads(payload) {
  return {
    type: types.GET_ADS,
    payload,
  };
}
export function get_ads_detail(payload) {
  return {
    type: types.GET_ADS_DETAIL,
    payload,
  };
}
export function save_go_back(payload) {
  return {
    type: types.SAVE_GO_BACK,
    payload,
  };
}
export function reset_ads(payload) {
  return {
    type: types.RESET_ADS,
    payload,
  };
}