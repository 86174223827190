
import { Input } from 'antd';
import moment from 'moment';
export const k = '38c11cb6f86a902501e5a34f451adb75'
export const uuidv4Short = () => {
    return "4xxx-yxxx-xxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
export const formatMoney = (number, lamtron) => {

    return number = (Math.round(number * 100) / 100);
}
export const isVietnamesePhoneNumberValid = (number) => {
    return /(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})\b/.test(number);
}
export const randomNumber = (min, max) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}


export const NumericInput = (props) => {
    const { value, onChange } = props;

    const handleChange = (e) => {

        const reg = /^-?\d*(\.\d*)?$/;

        if (reg.test(e.target.value) || e.target.value === '') {
            onChange(e);
        }
        else {
            e.target.value = e.target.value.replace(/\D/g, '');
            onChange(e);
        }
    };
    return (

        <Input
            {...props}
            onChange={handleChange}
        />
    );
};

export const curencyFormat = (value) => {
    if (value)
        return Number(parseInt(Math.round(value * 100) / 100)).toLocaleString() + " $"
    else {
        return "0 $"
    }
};
export const numberFormat = (value) => {
    if (value)
        return (Math.round(value * 100) / 100).toLocaleString()
    else {
        return "0"
    }
};

export const localeDate = {
    lang: {
        "locale": "vi_VN",
        placeholder: "Chọn một ngày",
        rangePlaceholder: [
            "Ngày bắt đầu",
            "Ngày cuối"
        ],
        today: 'Hôm nay',
        now: 'Bây giờ',
        backToToday: 'Quay lại hôm nay',
        ok: 'OK',
        clear: 'Xóa',
        month: 'Tháng',
        year: 'Năm',
        timeSelect: 'Chọn thời gian',
        dateSelect: 'Chọn ngày',
        monthSelect: 'Chọn tháng',
        yearSelect: 'Chọn năm',
        decadeSelect: 'Chọn thập kỷ',
        previousMonth: 'Tháng trước',
        nextMonth: 'Tháng tới',
        previousYear: 'Năm ngoái',
        nextYear: 'Năm sau',
        previousDecade: 'Thập kỉ trước',
        nextDecade: 'Thập kỉ tiếp theo',
        previousCentury: 'Thế kỉ trước',
        nextCentury: 'Thế kỉ tiếp theo',
        "yearFormat": "YYYY",
        "dateFormat": "M/D/YYYY",
        "dayFormat": "D",
        "dateTimeFormat": "M/D/YYYY HH:mm:ss",
        "monthFormat": "MMMM",
        monthBeforeYear: true
    },
    "timePickerLocale": {
        "placeholder": "Select time"
    },
    "dateFormat": "YYYY-MM-DD",
    "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
    "weekFormat": "YYYY-wo",
    "monthFormat": "YYYY-MM"
}

export const smoothScrollTo = (id) => {
    let i = 0;
    let x = setInterval(() => {
        try {
            const element = document.getElementById(id);
            i += 1
            if (element) {
                element.scrollIntoView({
                    block: 'center', behavior: 'smooth',
                });
                clearInterval(x)
                localStorage.setItem("savedScrollPosition", null)
            }
            if (i > 10) {
                localStorage.setItem("savedScrollPosition", null)
                clearInterval(x)
            }
        } catch (error) {
            console.log(error.message)
        }
    },
        500);
}
export const removeNullValues = (obj) => {
    Object.keys(obj).forEach(function (key) {
        if (obj[key] && typeof obj[key] === 'object') {
            if (Array.isArray(obj[key])) {
                // Remove empty array          
                if (Object.keys(obj[key]).length === 0) {
                    delete obj[key];
                }
                // Remove arrays with null elements          
                else if (obj[key].some((item) => item
                    === null)) {
                    delete obj[key];
                }
            } else {
                // Recursively remove null values for nested objects    
                removeNullValues(obj[key]);
                // Remove empty objects         
                if (Object.keys(obj[key]).length === 0) {
                    delete obj[key];
                }
            }
        } else {
            // Remove keys with null values   
            if (obj[key] === null) {
                delete obj[key];
            }
        }
    });
    return obj;
}

export const dateFormat = (value) => {
    if (value)
        return moment(value).format("DD-MMM-YYYY")
    else { return value }
}
