import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import React from "react";
import { Provider } from 'react-redux';
import { Route, Switch } from "react-router-dom";
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from "redux-saga";
import AppContainer from "./containers";

import createRootReducer from './reducers';
import rootSaga from "./sagas";

const createBrowserHistory = require('history').createBrowserHistory;
const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, routeMiddleware];
function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middlewares
      ),
    ),
  );

  sagaMiddleware.run(rootSaga);
  return store;
}
//////////////////

const store = configureStore();

const NextApp = () =>
{

   return ( <Provider store={store}>
    <ConnectedRouter history={history}>
        <Switch >
        <Route path="/" component={AppContainer}/>
        </Switch >
    </ConnectedRouter>
  </Provider>
  )
}


export default NextApp;
