import { Button, Checkbox, Input, message, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { KeyOutlined, LoginOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import { login, reset_user } from "../modules/user/actions";
const { Content } = Layout
const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const _user = useSelector(({ user }) => user.user);
  const _me = useSelector(({ user }) => user.me);

  const initialState = {
    username: localStorage.getItem("tcstore_username") ? localStorage.getItem("tcstore_username") : "",
    password: localStorage.getItem("tcstore_password") ? localStorage.getItem("tcstore_password") : "",
    remember: true
  }
  const [state, setState] = useState(initialState)
  const loginUser = () => {
    if (state.remember) {
      localStorage.setItem("tcstore_username", state.username)
      localStorage.setItem("tcstore_password", state.password)
    }
    else {
      localStorage.removeItem("tcstore_username")
      localStorage.removeItem("tcstore_password")
    }
    if (!state.username || !state.password)
      return message.warning("Please input full info")
    dispatch(login({
      device: "web",
      username: state.username,
      password: state.password
    }))
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      loginUser()
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)

    if (_me?.status == true) {
      history.push("/")
    }
    return () => {
      dispatch(reset_user({ remove: ["register", "user"] }))
    }
  }, [])
  return (

    <Layout style={{ height: "100vh" }}>

      <Content
        style={{
          background: "rgb(248 248 248)",
        }}
      >
        <div style={{
          minHeight: 654,
          background: "url(../assets/images/login-image.f8358c7fcbea8dafaa2b.png) 0 0/100% no-repeat"
        }}>
          <div className="center_login" style={{ width: isMobile ? "85%" : null, padding: 0, background: "white", }}>
            <div style={{ textAlign: "left", padding: "30px 0px 20px 30px", background: "rgb(72 81 87)", color: "#ccc" }}>
              <p>Login</p>
            </div>
            <div style={{ padding: 50 }} >

              <Input placeholder="Device" style={{ display: "none" }} />
              <Input prefix={<UserOutlined />} style={{ marginBottom: "20px" }} placeholder="Username" value={state.username} onChange={(e) => setState({ ...state, username: e.target.value })} />
              <Input.Password prefix={<KeyOutlined />} style={{ marginBottom: "20px" }} type="password" placeholder="Password" value={state.password}
                onChange={(e) => setState({ ...state, password: e.target.value })}
                onKeyDown={(e) => handleKeyDown(e)}
              />
              <Button icon={<UserOutlined />} style={{ width: "100%", margin: 0 }} type="primary" onClick={() => loginUser()} loading={_user.isLoading}
                className="btnDark" htmlType="submit">
                Sign in
              </Button>
            </div>
          </div>
        </div>


      </Content>

    </Layout>


  );
};

export default SignIn;
