import { httpClient as request } from "../../utils/Api";
export const setting_set = payload => {
  return  request.post("/setting/save", payload)
};

export const setting_get = payload => {
  return  request.post("/setting/server", payload)
};

export const setting_summarys = payload => {
  return request.post("/account/summarys", payload)
};



