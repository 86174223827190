import * as Icon from "@ant-design/icons";
import { Button, Card, Col, Input, message, Row, Select } from "antd";
import 'moment/locale/vi';
import { useEffect, useState } from "react";
import { Line, Pie } from 'react-chartjs-2';
import Item_Ads from "../../../components/Item_Ads";
import { Chart as ChartJS,  ArcElement, Tooltip, Legend, CategoryScale, LineController, LineElement, PointElement, LinearScale, Title } from 'chart.js';


import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { get_ads_detail, reset_ads } from "../../../modules/ads/actions";
import CircularProgress from "../../../components/CircularProgress";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale,PointElement, LineController,LineElement,Title);

const Ads_Detail = () => {
   const location = useLocation();
   let { id: adID } = useParams();
   let history = useHistory();
   const dispatch = useDispatch();
   const _adsDetail = useSelector(({ ads }) => ads.adsDetail);

   const options = {
      responsive: false,
      maintainAspectRatio: true,
      plugins: {
         legend: {
            position: 'top',
         },
         title: {
            display: false,
            text: ''
         }
      },
      pages: [],
      selectIndex: 0
   }
   const options_line = {
      responsive: false,
      maintainAspectRatio: true,
      plugins: {
         legend: {
            position: 'top',
         },
         title: {
            display: false,
            text: ''
         }
      },
      pages: [],
      selectIndex: 0
   }
   const initialState = {
      ads_detail: {},
      genderChart: {
         labels: [],
         data: []
      },
      countryChart: {
         labels: [],
         data: []
      },
      ageChart: {
         labels: [],
         data: []
      },
      pages: [],
      comments: [],
      selectIndex: 0,
      selectItem: {},
      displayComment: false,
      redirectShowIndex: null
   }

   const [state, setState] = useState(initialState)
   const renderChart = (detail) => {
      const { countryStats, genderStats, ageStats, pages, comments } = detail;
      let dataGender = [];
      let labelGender = [];
      let dataCountry = [];
      let labelCountry = [];
      let dataAge = [];
      let labelAge = [];
      if (genderStats) {
         genderStats.map((item) => {
            dataGender = [...dataGender, item.count]
            labelGender = [...labelGender, `${item.key} (${item.count})`]
         });
      }
      if (countryStats) {

         countryStats.map((item) => {
            console.log(item)
            dataCountry = [...dataCountry, item.count]
            labelCountry = [...labelCountry, `${item.key} (${item.count})`]

         });
      }
      if (ageStats) {

         ageStats.map((item) => {

            dataAge = [...dataAge, item.count]
            labelAge = [...labelAge, `${item.key} - ${Number(item.key) + 10} (${item.count})`]
         });
      }
      setState(state => ({
         ...state,
         ads_detail: detail,
         genderChart: { labels: labelGender, data: dataGender },
         countryChart: { labels: labelCountry, data: dataCountry },
         ageChart: { labels: labelAge, data: dataAge },
         pages: pages,
         selectIndex: 0,
         selectItem: pages?.length > 0 ? pages[0] : {},
         comments: comments
      }))
   }

   useEffect(() => {
      // const adIdRegex = /\/ads\/([a-zA-Z0-9_-\=]+)\b/;
      // let adId = window.location.href.match(adIdRegex);
      // if (adId && adId.length > 1 && adId[1] !== null && adId[1] !== undefined) {
      //    adId = adId[1]
      if (adID) {
         dispatch(get_ads_detail({
            id: adID
         }))
      }
      else {
         return message.warning("Not found ad ID");
      }
      return () => {
         dispatch(reset_ads({
            remove: ["adsDetail"]
         }))
      }

   }, [])
   useEffect(() => {
      if (_adsDetail.status) {
         renderChart(_adsDetail.data)
      }
      else if (_adsDetail.status == false && _adsDetail.msg !== null) {
         message.warning(_adsDetail.msg)
         history.push("/")
      }

   }, [_adsDetail])


   useEffect(() => {

      if (typeof state.selectIndex !== "undefined" && state.pages.length)
         setState(state => ({ ...state, selectItem: state.pages[state.selectIndex] }))
   }, [state.selectIndex])

   return (

      <div style={{
         width: "100%",
         width: "1100px",
         margin: "0 auto",
         marginTop: 110,

      }}>

         {state.ads_detail && state.ads_detail && Object.keys(state.ads_detail).length !== 0 ?
            <>

               <div style={{ padding: "10px 0px" }}>
                  <Button className="btnDark" icon={<Icon.RollbackOutlined />}
                     onClick={() => {

                        history.push('/ads', { isBack: true });
                     }}
                  >Back to ads</Button>
               </div>
               <Row>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24} style={{ padding: "0px 5px" }}>
                     <Item_Ads isDetail={true} data={state.ads_detail || {}} />
                     {state?.comments && state?.comments?.length ?
                        <Card bodyStyle={{ padding: "30px 10px" }}>
                           {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                           <Select className="selectEdit" style={{ width: 200 }} placeholder="Select a label" >
                              <Select.Option value="1" key={"1"} />
                           </Select>
                           <Button className="btnDark" icon={<Icon.SaveOutlined />}>Add New Label</Button>
                        </div> */}

                           <div style={{ marginTop: 10 }}>
                              <Button className="btnDark" onClick={() => setState(state => ({ ...state, displayComment: !state.displayComment }))}>{state.displayComment ? "Hide Comments" : "View Best Comments"}</Button>
                              <div style={{ marginTop: 10, display: state.displayComment ? "block" : "none" }}>
                                 {state.comments.map(item => {
                                    return (
                                       <div className="small-text ng-star-inserted" style={{ lineHeight: '25px' }}>
                                          <span style={{ fontWeight: 'bold', color: "#365899" }}>{item.userName}</span>
                                          &nbsp;
                                          <span >{item.text}</span>
                                          {item.likesNum ? <span className="ng-star-inserted">
                                             <img className="emoticon" src="/assets/images/emoticons/like.png" style={{ verticalAlign: "middle" }} />
                                             &nbsp;
                                             <span>{item.likesNum}</span>
                                          </span> : null}
                                       </div>
                                    )
                                 })}
                              </div>
                           </div>
                        </Card> : null
                     }
                     <Card style={{ marginTop: 10 }} bodyStyle={{ padding: "30px 10px" }} headStyle={{ padding: 10 }}
                        title={<p style={{ fontWeight: "900" }}>Demographics</p>}
                     >
                        {
                           state.genderChart?.data?.length ? <>
                              <p style={{ fontWeight: "900" }}>Gender</p>
                              <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                 <Pie
                                    width={300} height={300}

                                    options={options}
                                    data={{
                                       labels: state.genderChart.labels,
                                       datasets: [
                                          {
                                             data: state.genderChart.data,
                                             backgroundColor: ['rgb(51 102 204)', '#dc3912', "#ff9900", "#109618", "#990099", "#3b3eac"],
                                             hoverBackgroundColor: ['rgb(51 102 204)', '#dc3912', "#ff9900", "#109618", "#990099", "#3b3eac"],
                                          },

                                       ]
                                    }}
                                 />
                              </div>
                           </> : null
                        }
                        {
                           state.countryChart?.data?.length ? <>
                              <p style={{ fontWeight: "900" }}>Countries</p>
                              <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                 <Pie
                                    width={300} height={300}
                                    options={options}
                                    data={{
                                       labels: state.countryChart.labels,
                                       datasets: [
                                          {
                                             data: state.countryChart.data,
                                             backgroundColor: ['rgb(51 102 204)', '#dc3912', "#ff9900", "#109618", "#990099", "#3b3eac"],
                                             hoverBackgroundColor: ['rgb(51 102 204)', '#dc3912', "#ff9900", "#109618", "#990099", "#3b3eac"],
                                          },

                                       ]
                                    }}
                                 />
                              </div>
                           </> : null
                        }
                        {
                           state.ageChart?.data?.length ? <>
                              <p style={{ fontWeight: "900" }}>Age groups</p>
                              <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                 <Pie
                                    width={300} height={300}
                                    options={options}
                                    style={{ width: 300, height: 300 }}
                                    data={{
                                       labels: state.ageChart.labels,
                                       datasets: [
                                          {
                                             data: state.ageChart.data,
                                             backgroundColor: ['rgb(51 102 204)', '#dc3912', "#ff9900", "#109618", "#990099", "#3b3eac"],
                                             hoverBackgroundColor: ['rgb(51 102 204)', '#dc3912', "#ff9900", "#109618", "#990099", "#3b3eac"],
                                          },

                                       ]
                                    }}
                                 />
                              </div>
                           </> : null
                        }
                     </Card>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24} style={{ padding: "0px 5px" }}>
                     <Card style={{ marginTop: 10 }} bodyStyle={{ padding: "30px 10px" }} headStyle={{ padding: 10 }}
                        title={<p style={{ fontWeight: "900" }}>Likes over time</p>}
                     >
                        {
                           state.genderChart?.data?.length ? <>
                           
                              <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                 <Line
                                    width={"500px"} height={200}

                                    options={options_line}
                                    data={{
                                       labels: [],
                                       datasets: [
                                          {
                                             label: 'Likes over time',
                                             data: [],
                                             borderColor: '#4bc0c0',
                                             backgroundColor: '#e5e5e5',
                                           },

                                       ]
                                    }}
                                 />
                              </div>
                           </> : null
                        }
                     </Card>
                     {state.pages?.length ? <Card style={{ marginTop: 10 }} bodyStyle={{ padding: "30px 10px" }}>
                        <h1 style={{ fontSize: "2em" }}>Landing pages</h1>

                        {state.pages.length > 1 ?
                           <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <Button icon={<Icon.LeftOutlined />} className="btnDark" onClick={(() => {
                                 if (state.selectIndex <= state.pages.length - 1 && state.selectIndex !== 0) setState(state => ({ ...state, selectIndex: (state.selectIndex - 1) }))
                              })}>Previous</Button>
                              <p>{state.selectIndex + 1} of {state.pages.length}</p>
                              <Button className="btnDark" onClick={(() => {
                                 if (state.selectIndex < state.pages.length - 1)
                                    setState(state => ({ ...state, selectIndex: (state.selectIndex + 1) }))

                              })
                              }>Next <Icon.RightOutlined /></Button>
                           </div> : null
                        }
                        <div style={{ marginTop: 10 }}>
                           {state.selectItem?.screenFileName ?
                              <>
                                 <h2>Screenshot</h2>
                                 <a target={"_blank"} href={state.selectItem.screenFileName}>
                                    <div style={{
                                       width: "100%",
                                       height: 250,
                                       backgroundSize: 'cover',
                                       border: '2px solid grey',
                                       backgroundImage: `url(${state.selectItem.screenFileName})`,
                                    }}></div>
                                 </a>
                              </> : null
                           }

                           {
                              state.selectItem?.finalUrl ?
                                 <>
                                    <h2 style={{ marginTop: 20 }}>Final URL</h2>
                                    <a href={state.selectItem.finalUrl} target="_blank" style={{ wordWrap: "break-word" }} >{state.selectItem.finalUrl}</a>
                                 </>
                                 : null
                           }
                           {
                              state.selectItem?.outgoingLinks && state.selectItem?.outgoingLinks?.length ?
                                 <>
                                    <h2 style={{ marginTop: 20 }}>Outgoing Links</h2>
                                    <div className="ui-table">
                                       <table>
                                          <thead className="ui-table-thead">
                                             <tr className="ng-star-inserted">

                                                <th >Links</th>
                                             </tr>
                                          </thead>

                                          <tbody className="ui-table-tbody">

                                             {state.selectItem.outgoingLinks.map((item, index) => {
                                                return (
                                                   <tr className="ng-star-inserted" style={{ wordWrap: "break-word" }}>
                                                      <td >
                                                         <b>URL: </b>{item.url} <br />
                                                         {
                                                            item?.redirects && item?.redirects?.length ?
                                                               <div>
                                                                  <Button className="btnDark"
                                                                     onClick={() => {
                                                                        setState(state => ({ ...state, redirectShowIndex: state.redirectShowIndex == null ? index : null }))
                                                                     }}
                                                                     icon={state.redirectShowIndex == null ? <Icon.EyeFilled /> : <Icon.EyeInvisibleFilled />} >{state.redirectShowIndex !== null ? "Hide " : ""}Redirects</Button>
                                                                  {state.redirectShowIndex == index ?
                                                                     <p style={{ color: "green" }}>{item.redirects.join("\n\r")}</p>

                                                                     : null}
                                                               </div> : null
                                                         }
                                                         {item.finalUrl ? <> <b>Final URL: </b>{item.finalUrl}</> : null}
                                                      </td>

                                                   </tr>
                                                )
                                             })}

                                          </tbody>
                                       </table>
                                    </div>
                                 </> : null
                           }
                           {state.selectItem?.techstack && state.selectItem?.techstack?.length ?
                              <>
                                 <h2 style={{ marginTop: 20 }}>Technology</h2>
                                 <div className="ui-table">
                                    <table>
                                       <thead className="ui-table-thead">
                                          <tr className="ng-star-inserted">
                                             <th >Type</th>
                                             <th >Tech</th>
                                          </tr>
                                       </thead>

                                       <tbody className="ui-table-tbody">

                                          {state.selectItem.techstack.map(item => {
                                             return (
                                                <tr className="ng-star-inserted">
                                                   <td >{item.type}</td>
                                                   <td >{item.tech.join(", ")}</td>
                                                </tr>

                                             )
                                          })}

                                       </tbody>
                                    </table>
                                 </div>
                              </> : null
                           }

                        </div>
                     </Card> : null}
                  </Col>
               </Row>
            </> : <CircularProgress className={"centerText"} />

         }
      </div>
   )
}
export default Ads_Detail