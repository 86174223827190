import { uuidv4Short } from "../utils/help";
import { UnlockOutlined, LockOutlined, CloseOutlined } from '@ant-design/icons';

const Panel_Filter = (props) => {

    return (
        <div className="panelFilter" key={uuidv4Short()}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    {
                        props.haveLock ?
                            <div onClick={props.onLockChange}>
                                {props.locked ? <LockOutlined /> : <UnlockOutlined />}
                            </div>
                            : null
                    }
                </div>

                <div style={{ margin: "0 10px" }}>
                    <span>{props.label}: </span><span>{props.value}</span>
                </div>
                {props.haveDel ? <div onClick={props.onDelete}><CloseOutlined /></div> : null}
                
            </div>
        </div>
    )
}
export default Panel_Filter;