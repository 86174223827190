import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../utils/asyncComponent";
import Users from "./Users";
import Accounts from "./Accounts";

const App = ({ match, history, location }) => {

    return (
        <Switch>
            <Route path={`/admin/accounts`} component={Accounts} />
            <Route path={`/admin/users`} component={Users} />
            <Route path={`/admin`} component={Users} />
        </Switch>

    );
}



export default App;
