
import { CloseOutlined, DownOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, Radio, Slider } from "antd";
import 'moment/locale/vi';
import DatePicker from "./DatePicker";
import '../assets/DatePicker.css';
import { useState, useRef, useEffect } from "react";
import { uuidv4Short } from "../utils/help";
export const ContentSelect = (props) => {


    return (
        <div>
            <div>
                {
                    props.item && props.item.length ?
                        props.item.map((it) =>
                            <div
                                key={uuidv4Short()}
                                className="item-background"
                                style={{
                                    width: "100%",
                                    background: props?.value?.value?.includes(it.value) ? "#5180ce" : null
                                }}
                                onClick={(e) => props.onChange(it)}
                            >{it.label}</div>

                        ) : null
                }
            </div>
            <div style={{ padding: 10, textAlign: "left", display: "flex", justifyContent: "space-between" }}>
                <Button className="btnDark" onClick={props.onReset} size="small" icon={<CloseOutlined />}>Reset</Button>
            </div>
        </div>
    )
};
export const ContentRadio = (props) => (
    <div>
        <div style={{ width: 200, paddingTop: 10 }}>
            <Radio.Group onChange={(e) => props.onChange(e.target.value)} value={props.value}>
                <Radio className="item-radio" value={null}>{props.item.join(" or ")}</Radio>
                {
                    props.item && props.item.length ?
                        props.item.map((item) =>
                            <Radio key={uuidv4Short()} className="item-radio" value={item} >{item}</Radio>) : null
                }
            </Radio.Group>
        </div>

        <div style={{ padding: 10, textAlign: "left", display: "flex", justifyContent: "space-between" }}>
            <Button onClick={props.onReset} className="btnDark" size="small" icon={<CloseOutlined />}>Reset</Button>
        </div>
    </div>
);
export const ContentItemSearch = (props) => {
    let [item, setItem] = useState(props.item)
    return (
        <div style={{ width: 200 }}>
            <div style={{ minHeight: 250, padding: 10 }}>
                <div style={{
                    border: "2px solid #cccc", height: 200, overflow: "scroll", overflowX: "hidden"

                }}>
                    <div>
                        <Input className="inputSearch" prefix={<SearchOutlined />} onChange={(e) => {
                            try {
                                let string = e.target.value;
                                let exist_item = props.item.filter(it => it.label.toLowerCase().includes(string.toLowerCase()) ? it : null)
                                setItem(exist_item)
                            } catch (error) { }
                        }
                        } placeholder="Search" />
                    </div>
                    {
                        item && item.length ?
                            item.map((it) =>
                                <div key={uuidv4Short()} className="item-background" style={{ background: props?.value?.value == it.value ? "#5180ce" : null }}
                                    onClick={(e) => props.onChange(it)} >{it.label}</div>) : null
                    }
                </div>
                <div style={{ paddingTop: 10, textAlign: "left" }}>
                    <Button onClick={props.onReset} className="btnDark" size="small" icon={<CloseOutlined />}>Reset</Button>
                </div>
            </div>
        </div>
    )
}

export const ContentItemCheckbox = (props) => {
    let [item, setItem] = useState(props.item)
    let itemValue = props.value.map(option => option.value);
    return (
        <div style={{ width: 200 }}>
            <div style={{ minHeight: 250, padding: 10 }}>
                <div style={{
                    border: "2px solid #cccc", height: 200, overflow: "scroll", overflowX: "hidden"

                }}>
                    <div>
                        <Input className="inputSearch" prefix={<SearchOutlined />} onChange={(e) => {
                            try {
                                let string = e.target.value;
                                let exist_item = props.item.filter(it => it.label.toLowerCase().includes(string.toLowerCase()) ? it : null)
                                setItem(exist_item)
                            } catch (error) { }
                        }
                        } placeholder="Search" />
                    </div>

                    <div>
                        <Checkbox.Group
                            defaultValue={[]}
                            onChange={(e) => props.onChange(e)}
                            value={itemValue}
                        >
                            {
                                item && item.length ?
                                    item.map((it) =>
                                        <Checkbox
                                            key={uuidv4Short()}
                                            className="item-background"
                                            style={{
                                                width: "100%",
                                                background: itemValue.includes(it.value) ? "#5180ce" : null
                                            }}
                                            value={it.value}>{it.label}</Checkbox>

                                    ) : null
                            }
                        </Checkbox.Group>
                    </div>
                </div>
                <div style={{ marginTop: 10, display: "flex", justifyContent: "space-between" }}>
                    <Button onClick={props.onReset} className="btnDark" size="small" icon={<CloseOutlined />}>Reset</Button>
                </div>
            </div>

        </div>


    )
}
export const ContentSlider = (props) => {

    return (
        <div style={{ minWidth: 200 }}>
            <div className="item-slider">
                <p>From {props.min} to {props.max} +</p>
                <div>
                    <Slider
                        onAfterChange={(e) => props.onChange(e)}
                        range={{
                            draggableTrack: true
                        }}
                        min={props.min} max={props.max}
                        // value={props.value || props.defaultValue}
                        defaultValue={props.defaultValue}
                    />
                </div>
            </div>
            <div style={{ padding: 10, textAlign: "left", display: "flex", justifyContent: "space-between" }}>
                <Button onClick={props.onReset} className="btnDark" size="small" icon={<CloseOutlined />}>Reset</Button>
            </div>
        </div>

    )
}
export const ContentDatePicker = (props) => {
    return (
        <div >
            <div>
                <DatePicker
                    onClose={props.onClose}
                    value={props.value}
                    onChange={props.onChange}
                    onReset={props.onReset}

                />
            </div>

        </div>
    )
}
function useOutsideAlerter(ref, props) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                console.log("You clicked outside of me!");
                props.onClose()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}
export const SelectHeader = (props) => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props);
    return (
        <div >
            <table className="selectClass" onClick={props.onChangeStatus} >
                <tbody className="selectBody"><tr >
                    <td >{props.text}</td>
                    <td >{props.open ? <UpOutlined /> : <DownOutlined />}</td>
                </tr>
                </tbody></table>
            {
                props.open ? <div ref={wrapperRef} className="popup-picker">
                    {props.content}
                </div> : null
            }
        </div>
    )
}
