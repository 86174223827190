import { CheckCircleOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Card, Input, message, Pagination, Select, Space, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import 'moment/locale/vi';
import { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from 'use-debounce';
import { delete_user, get_users, register, reset_user, update_user } from "../../../modules/user/actions";
import { numberFormat } from "../../../utils/help";
import UserView from "./UserView";

const { Option } = Select;
const { TextArea } = Input;

const Users = (props) => {
   const dispatch = useDispatch();
   const _userList = useSelector(({ user }) => user.userList);
   const _user = useSelector(({ user }) => user.me.user);
   const _updateUser = useSelector(({ user }) => user.updateUser);
   const _createUser = useSelector(({ user }) => user.register);
   const _deleteUser = useSelector(({ user }) => user.deleteUser);

   let initialState = {
      search: null,
      sort_by: 'default',
      page: 0,
      role: null,
      note: null,
      user_type: null,
      userSelect: null,
      userNote: null,
      isVisibleUser: false,
      isVisibleConfirmDelete: false
   }
   const [state, setState] = useState(initialState)
   const [search] = useDebounce(state.search, 1000);

   const handleGetUser = async () => {
      dispatch(get_users({
         search: state.search,
         role: state.role,
         user_type: state.user_type,
         page: state.page,
         sort_by: state.sort_by
      }))
   }
   useEffect(() => {
      if (state.sort_by !== null)
       {
         handleGetUser()
       }
   }, [state.sort_by])

   useEffect(() => {
      if (search !== null)
       {
         handleGetUser()
       }
   }, [search])

   useEffect(() => {
      if (state.user_type !== null)
      {
         handleGetUser()
       }
   }, [state.user_type])

   useEffect(() => {
      if (state.role !== null)
       {
         handleGetUser()
       }
   }, [state.role])

   useEffect(() => {
      window.scrollTo(0, 0)
      console.log(1)
      handleGetUser()
      return () => {
         dispatch(reset_user({ remove: ["userList", "updateUser","deleteUser"] }))
      };
   }, [])
   
   const handleUpdateNote = async () => {
      if (state.note !== null && state.userNote && state.note !== state.userNote.note)
         dispatch(update_user({
            ...state.userNote,
            note: state.note
         }))
   }
   const handleShowInfoUser = (record) => {
      setState({ ...state, userSelect: record, isVisibleUser: true })
   }
   const handleUpdateUser = () => {
      dispatch(update_user({ ...state.userSelect }))
   }
   const handleCreateUser = () => {
      dispatch(register({ ...state.userSelect }))
   }
   const deleteConfirm = () => {
      setState({ ...state, isVisibleConfirmDelete: false })
      dispatch(delete_user({
         "_id": state.userSelect._id,
      }))
   }
  useEffect(() => {
      if (_deleteUser.status) message.success(_deleteUser.msg)
      else if (!_deleteUser.status && _deleteUser.msg !== null) message.warning(_deleteUser.msg)
   }, [_deleteUser])
   useEffect(() => {
      if (_createUser.status) message.success(_createUser.msg)
      else if (!_createUser.status && _createUser.msg !== null) message.warning(_createUser.msg)
   }, [_createUser])

   useEffect(() => {
      if (_updateUser.status) message.success(_updateUser.msg)
      else if (!_updateUser.status && _updateUser.msg !== null) message.warning(_updateUser.msg)
   }, [_updateUser])

   let columns = [
      {
         title: '#',
         key: 'index',
         width: 50,
         align: "center",
         render: (text, record, index) => (_userList.current_page * _userList.per_page) + index + 1,
      },

      {
         title: 'Infomation',
         dataIndex: 'username',
         key: 'username',
         width: 300,
         ellipsis: true,
         render: (text, record) => {
            return <>
               <Space>
                  <Avatar size="default" src={"/assets/images/user.png"} />
                  <div style={{ alignItems: "center" }}>
                     <span>
                        {record.username} - {record.user_type == 0 ? "Free" : "Premium"}
                     </span><br />

                     <span>
                        Expired: {moment(record.expiry_date).format("DD/MM/YYYY")}
                     </span>
                  </div>
               </Space>
            </>
         }
      },
      {
         title: 'Role',
         dataIndex: 'role',
         key: 'role',
         width: 100,
         ellipsis: true,
         align: "center",
         render: (text) => <Tag style={{ borderRadius: 10 }} color={text == 0 ? "red" : "green"}>{text == 0 ? "ADMIN" : "USER"}</Tag>
      },
      {
         title: "View Left",
         key: 'viewsLeft',
         dataIndex: 'viewsLeft',
         width: 100,
         align: "right",
         render: (text) => numberFormat(text)
      },
      {
         title: "Views Period",
         key: 'viewsCurrentPeriod',
         dataIndex: 'viewsCurrentPeriod',
         width: 100,
         align: "right",
         render: (text) => numberFormat(text)
      },
      {
         title: "Login Info",
         key: 'ip_web',
         dataIndex: 'ip_web',
         width: 160,

         render: (text, record) =>
            <div>
               <span>
                  IP: {record.ip_web}
               </span><br />
               <span>
                  Last: {record.last_login ? moment(record.last_login).format("DD/MM/YYYY") : "Unknown"}
               </span>

            </div>
      },
      {
         title: "Note",
         key: 'note',
         dataIndex: 'note',
         width: 200,
         render: (text, record) => <div style={{ display: "flex", flexDirection: "row" }}>
            <TextArea style={{ height: "60px" }}
               onClick={() => {
                  setState({ ...state, note: record.note, userNote: record })
               }}
               onChange={(e) => {
                  setState({ ...state, note: e.target.value })
               }}
               rows={2} value={state.userNote && state.userNote._id == record._id ? state.note : text} ></TextArea>
            <Button onClick={() => handleUpdateNote()} style={{ height: "60px", width: "35px", marginLeft: 5 }} className="green" icon={<CheckCircleOutlined />}></Button>
         </div>
      },
      {
         title: 'Status',
         key: 'status',
         dataIndex: 'status',
         width: 100,
         align: "center",

         render: (text) => text ? <Badge color={"green"} text="Active" /> : <Badge color={"red"} text="Deactive" />
      },
      {
         title: 'Auto Renew',
         key: 'auto_renew',
         dataIndex: 'auto_renew',
         width: 100,
         align: "center",

         render: (text) => text ? <Badge color={"green"} text="Yes" /> : <Badge color={"red"} text="No" />
      },
      {
         title: 'Menu',
         width: 80,
         align: "center",
         // fixed: 'right',
         render: (text, record) => (
            <Space>
            <Tooltip placement="bottom" title={"User Edit"}>
               <Button
                  type="primary"
                  size="small"
                  icon={<EditOutlined />}
                  className='ant-btn gx-btn-success'
                  onClick={() => {
                     handleShowInfoUser(record)
                  }}
               ></Button>
            </Tooltip>
            <Tooltip placement="bottom" title={"Delete User"}>
                  <Button
                     type="primary"
                     size="small"
                     danger
                     icon={<DeleteOutlined />}

                     onClick={() => {
                        setState({ ...state, userSelect: record, isVisibleConfirmDelete: true })
                     }}
                  ></Button>
               </Tooltip>
            </Space>
         ),
      },
   ];
   let columns_mobile = [
     {
         title: '#',
         key: 'index',
         width: 50,
         align: "center",
         render: (text, record, index) => (_userList.current_page * _userList.per_page) + index + 1,
      },

      {
         title: 'Infomation',
         dataIndex: 'username',
         key: 'username',
         width: 300,
         ellipsis: true,
         render: (text, record) => {
            return <>
               <Space>
                  <Avatar size="default" src={"/assets/images/user.png"} />
                  <div style={{ alignItems: "center" }}>
                     <span>
                        {record.username} - {record.user_type == 0 ? "Free" : "Premium"}
                     </span><br />

                     <span>
                        Expired: {moment(record.expiry_date).format("DD/MM/YYYY")}
                     </span>
                  </div>
               </Space>
            </>
         }
      },
      {
         title: 'Menu',
         width: 60,
         align: "center",
         // fixed: 'right',
         render: (text, record) => (
            <Tooltip placement="bottom" title={"User Edit"}>
               <Button
                  type="primary"
                  size="small"
                  icon={<EditOutlined />}
                  className='ant-btn gx-btn-success'
                  onClick={() => {
                     handleShowInfoUser(record)
                  }}
               ></Button>
            </Tooltip>
         ),
      },
   ];
   const optionTable = {
      bordered: true,
      loading: _userList.isLoading,
      size: 'small',
      showHeader: true,
      columns: isMobile ? columns_mobile : columns,
      dataSource: [..._userList.data],
      pagination: { position: ['none', 'none'], defaultPageSize: _userList.per_page },
      scroll: isMobile ? {} : { y: "calc(100vh - 300px)", x: 1000 }
   };

   const changePage = (page) => {
      setState({ ...state, page: page })
      dispatch(get_users({
         search: state.search,
         sort_by: state.sort_by,
         page: page - 1,
      }))
   }

   return (
      <>
       <SweetAlert show={state.isVisibleConfirmDelete}
            warning
            showCancel
            confirmBtnText={"Oke. Delete"}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={`Are you you confirm delete this user?`}
            onConfirm={deleteConfirm}
            onCancel={() => setState({ ...state, isVisibleConfirmDelete: false })}
         ></SweetAlert>
          <Card
         bodyStyle={{ padding: 10 }}
         headStyle={{ padding: 10 }}
         size="small"
         title="User Management"
         extra={
            <div>
               <Select
                  defaultValue={state.sort_by}
                  value={state.sort_by}
                  allowClear={true}
                  placeholder="Sort by column"
                  style={{ width: isMobile ? "100%" : "150px", marginRight: 10, marginTop: 10 }}
                  onChange={(value) => {
                     setState({ ...state, sort_by: value })
                  }}
               >
                  <Option value='default'>Default</Option>
                  <Option value='views_left'>Views Left</Option>
                  <Option value='login_recent'>Login recent</Option>
               </Select>
               <Input type="search"
                  style={{ width: isMobile ? "100%" : "200px", }}
                  placeholder="Find with username"
                  onChange={(e) => setState({ ...state, search: e.target.value })}
                  value={state.search}
               ></Input>
               <Select
                  defaultValue={state.role}
                  value={state.role}
                  allowClear={true}
                  placeholder="Role filter"
                  style={{ width: isMobile ? "100%" : "150px", marginLeft: !isMobile ? 10 : 0, marginTop: 10 }}
                  onChange={(value) => {
                     setState({ ...state, role: value })
                  }}
               >
                  <Option value={0}>Admin</Option>
                  <Option value={1}>User</Option>
               </Select>
               <Select
                  defaultValue={state.user_type}
                  value={state.user_type}
                  allowClear={true}
                  placeholder="User type filter"
                  style={{ width: isMobile ? "100%" : "150px", marginLeft: !isMobile ? 10 : 0, marginTop: 10 }}
                  onChange={(value) => {
                     setState({ ...state, user_type: value })
                  }}
               >
                  <Option value={0}>Free</Option>
                  <Option value={1}>Premium</Option>
               </Select>
               <Button type="primary" style={{ width: isMobile ? "100%" : "150px", marginLeft: !isMobile ? 10 : 0, marginTop: 10 }} onClick={() => {
                  setState({
                     ...state,
                     userSelect: {
                        _id: null,
                        role: 1,
                        auto_renew: true,
                        expiry_date: moment().add("days", 30),
                        user_type: 0,
                        status: true
                     },
                     isVisibleUser: true
                  })
               }} className="color1">
                  Create User
               </Button>
            </div>

         }
         style={{ width: "100%" }}>

         <Table
            {...optionTable}
            rowKey={(record) => record._id}
         />
         <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
            <Pagination current={_userList.current_page + 1} total={_userList.total} pageSize={_userList.per_page} onChange={changePage} style={{ paddingTop: 10 }} showSizeChanger={false} />
         </Space>
         {
            state.userSelect ? <UserView _update={_updateUser} _create={_createUser} isMobile={isMobile} state={state} _user={_user} setState={setState}
               handleUpdate={handleUpdateUser} handleCreate={handleCreateUser} /> : null
         }

      </Card>
      
      </>
     

   )
}
export default Users