
import { MenuOutlined } from '@ant-design/icons';
import { Layout, message, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Router from "./Router";

import { logout } from '../modules/user/actions';
import { httpClient } from '../utils/Api';
import { numberFormat } from '../utils/help';
import { setting_get } from '../modules/setting/actions';


const { Header, Sider, Content } = Layout;


const App = (props) => {
    const dispatch = useDispatch()
    const { match, history, location } = props
    const _me = useSelector(({ user }) => user.me);
    const user = _me.user

    const _adsList = useSelector(({ ads }) => ads.adsList);
    const _settingList = useSelector(({ setting }) => setting.settingList);


    const initialState = {
        menuOpen: false,
        link: null,
        isVisibleSetting: false,
        alert: null
    }
    const [state, setState] = useState(initialState)

    const menus = [

        {
            label:
                <Link to="/ads">
                    <span style={{ fontSize: 14, marginLeft: 8 }}>Ad Search</span>
                </Link>,
            key: '/ads',

        },
        {
            label:
                !isMobile ? <a><Popover placement="bottom" title={"Your Account"} style={{ marginTop: "-10px" }} content={<div>
                    <p style={{ padding: 20, textAlign: "center" }}><b>You have</b><br />{numberFormat(user?.viewsLeft)} views left</p>
                </div>} trigger="click">
                    <span style={{ fontSize: 14, marginLeft: 8 }}>My Account</span>
                </Popover></a> : <span style={{ fontSize: 14, marginLeft: 8 }}>Views left: {numberFormat(user?.viewsLeft)}</span>
            ,
            key: '/account',

        },
        {
            label:
                <Link to="/admin/users">
                    <span style={{ fontSize: 14, marginLeft: 8 }}>Admin</span>
                </Link>,
            key: '/admin/users',

        },

        {
            label: <a><span onClick={() => onLogoutClick()} style={{ fontSize: 14, marginLeft: 8 }}>Logout</span></a>,
            key: '/logout',

        },

    ];
    const onLogoutClick = () => {
        dispatch(logout())
        localStorage.removeItem('tcstore_token');
        httpClient.defaults.headers.common['Authorization'] = '';
        history.push('/signin');
        window.location.reload()
    }


    useEffect(() => {
        if (!user?._id || (user && user?.status == false && user?.msg !== null)) {
            if (user?._id !== undefined) message.warning("Please login")
            history.push("/signin")
        }
    }, [user])

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(setting_get())
    }, [])
    
    useEffect(() => {
        if (_settingList.status) {
            let configs = _settingList.data.find(item => item.key == "configs")
            console.log(configs)
            if (configs && configs.value) {

                if (configs?.value?.alert && configs?.value?.alert?.message)
                    setState(state => ({ ...state, alert: { ...configs.value.alert } }))
            }

        }
        else if (_settingList.status == false && _settingList.msg !== null) {
            message.warning(_settingList.msg)
        }
    }, [_settingList])

    return (
        <Layout style={{
            minHeight: '100vh',

        }}>

            <Layout style={{ background: "#fafafa" }}>

                <Header style={{
                    padding: isMobile ? "0 5px" : "0 20px", zIndex: 1010,
                    position: "fixed",

                    width: "100%",
                    backgroundColor: "#3e464c", height: 60,
                    boxShadow: "0 2px 10px 0 rgb(0 0 0 / 16%), 0 2px 5px 0 rgb(0 0 0 / 26%)",
                    // padding:0, margin: 0
                }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="logo" >
                            <a href="/ads">
                                <img src="../assets/images/logo.png" style={{ width: '150px' }} />
                            </a>
                        </div>
                        {
                            user?._id ?
                                <div style={{ display: "flex" }}>
                                    {_adsList.isLoading ?
                                        <img src="../assets/images/loading-indicator2.gif" style={{ width: "45px", height: "45px", margin: "8px 0px" }} /> : null}
                                    {!isMobile ? <div className="menu">
                                        {

                                            menus.map(menu => {
                                                if (user.role == 1 && menu.key.includes("admin"))
                                                    return null
                                                else return <div>{menu.label}</div>
                                            })
                                        } </div> :

                                        <Popover placement="bottomRight" trigger={"click"}
                                            overlayStyle={{ marginLeft: "-6px" }}
                                            content={
                                                <div className="menu_mobile">
                                                    {
                                                        menus.map(menu => {
                                                            if (user.role == 1 && menu.key.includes("admin"))
                                                                return null
                                                            else return <div>{menu.label}</div>
                                                        })
                                                    }
                                                </div>
                                            } title={`Xin chào ${user?.name}!`}>
                                            <MenuOutlined

                                                type="ghost"
                                                style={{ border: "none", color: "white", padding: 15, fontSize: 30, color: "#dddd" }}

                                            />
                                        </Popover>

                                    }
                                </div> : null
                        }

                    </div>

                </Header>
                {
                    !window.location.href.includes("/admin") &&
                        state.alert && state.alert.status && state.alert.message ? <div class="alert_label" style={{ marginTop: 60 }}>{state.alert.message}</div> :
                        null
                }
                <Content style={{
                    "flexDirection": "column",
                    "boxSizing": "border-box",
                    "display": "flex",
                    "maxWidth": "100%",
                    "placeContent": "flex-start",
                    "alignItems": "center",
                    height: "100vh"
                }}>
                    <Router match={match} location={location} history={history} />
                </Content>
            </Layout>
        </Layout>



    )

};

export default App;
