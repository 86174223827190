
export const GET_ADS = "ads/GET_ADS";
export const GET_ADS_SUCCESS = "ads/GET_ADS_SUCCESS";
export const GET_ADS_ERROR= "ads/GET_ADS_ERROR";

export const GET_ADS_DETAIL = "ads/GET_ADS_DETAIL";
export const GET_ADS_DETAIL_SUCCESS = "ads/GET_ADS_DETAIL_SUCCESS";
export const GET_ADS_DETAIL_ERROR= "ads/GET_ADS_DETAIL_ERROR";

export const SAVE_GO_BACK = "ads/SAVE_GO_BACK";
export const RESET_ADS = "ads/RESET_ADS";