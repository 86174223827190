import * as Types from "./constants";

const initState = {
  accountList: {
    msg: null,
    status: false,
    isLoading: false,
    data: [],
    total: 0,
    last_page: 0,
    current_page: 0,
    per_page: 20,
  },
  addAccount: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  deleteAccount: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  updateAccount: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },

  accountInfo: {
    isLoading: false,
    status: false,
    msg: null,
    data: 0
  },
};
export default function accountReducer(state = initState, action = {}) {
  switch (action.type) {
    case Types.ADD_ACCOUNT:
      return {
        ...state,
        addAccount: {
          ...state.addAccount,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.ADD_ACCOUNT_SUCCESS:
      if (action.payload.status == true)
        state.accountList.data = [action.payload.data].concat(state.accountList.data)

      return {
        ...state,
        accountList: {
          ...state.accountList,
          data: state.accountList.data
        },
        addAccount: {
          ...state.addAccount,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.ADD_ACCOUNT_ERROR:
      return {
        ...state,
        addAccount: {
          ...state.addAccount,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };

    /////////
    case Types.UPDATE_ACCOUNT:
      return {
        ...state,
        updateAccount: {
          ...state.updateAccount,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.UPDATE_ACCOUNT_SUCCESS:
      if (action.payload.status == true) {
        let objIndex = state.accountList.data.findIndex((obj => obj._id == action.payload.data._id));
        state.accountList.data[objIndex] = action.payload.data
      }
      return {
        ...state,
        accountList: {
          ...state.accountList,
          data: [...state.accountList.data]
        },
        updateAccount: {
          ...state.updateAccount,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.UPDATE_ACCOUNT_ERROR:
      return {
        ...state,
        updateAccount: {
          ...state.updateAccount,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };

    /////////
    case Types.DELETE_ACCOUNT:
      return {
        ...state,
        deleteAccount: {
          ...state.deleteAccount,
          status: false,
          isLoading: true,
          msg: null,
        }
      };
    case Types.DELETE_ACCOUNT_SUCCESS:
      if (action.payload.status) {
        state.accountList.data = state.accountList.data.filter((item) => item._id !== action.payload.data._id)
      }
      return {
        ...state,
        accountList: {
          ...state.accountList,
          data: state.accountList.data
        },
        deleteAccount: {
          ...state.deleteAccount,
          isLoading: false,
          ...action.payload,
        }
      };
    case Types.DELETE_ACCOUNT_ERROR:
      return {
        ...state,
        deleteAccount: {
          ...state.deleteAccount,
          ...action.payload,
          status: false,
          isLoading: false,
        }
      };
    ///////

    case Types.GET_ACCOUNT:
      return {
        ...state,
        accountList: {
          ...state.accountList,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountList: {
          ...state.accountList,
          ...action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_ACCOUNT_ERROR:
      return {
        ...state,
        accountList: {
          ...state.accountList,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
    case Types.GET_ACCOUNT_INFO:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_ACCOUNT_INFO_SUCCESS:
      if (action.payload.status == true) {
        let objIndex = state.accountList.data.findIndex((obj => obj._id == action.payload.data._id));
        state.accountList.data[objIndex] = action.payload.data
      }
      return {
        ...state,
        accountList: {
          ...state.accountList,
          data: [...state.accountList.data],
 
        },
        accountInfo: {
          ...state.accountInfo,
          data: action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.GET_ACCOUNT_INFO_ERROR:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////

    case Types.RESET_ACCOUNT:
      let newState = {}
      if (action.payload.remove[0] == "all")
        newState = initState
      else
        for (let [key, value] of Object.entries(state)) {
          if (action.payload.remove.includes(key))
            newState[key] = { ...initState[key] }
          else newState[key] = value
        };
      return newState

    default:
      return state;
  }
}
