import { DownloadOutlined, ExpandAltOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Space, Tooltip } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { numberFormat } from '../utils/help';
import { useDispatch, useSelector } from "react-redux";

moment.locale('en');

const Item_Ads = (props) => {
    let history = useHistory();
    const _saveList = useSelector(({ ads }) => ads.saveList);
    let data = props.data
    const headerLeft = () => {
        return (
            <div>
                <Row>
                    <Col><img width={40} height={40} src={data?.isIg ? "../assets/images/ig-logo.png" : "../assets/images/fb-logo.png"} /></Col>
                    <Col ><img width={40} height={40} style={{ border: "1px solid #cccc", marginLeft: 10 }} src={data?.actor?.profilePicture} /></Col>
                    <Col>
                        <div style={{ paddingLeft: 10 }}>
                            <Tooltip placement="bottomLeft" title={
                                <p>
                                    <span>{data?.actor?.name}</span><br />
                                    <span>{data?.actor?.userId}</span>
                                </p>
                            } arrowPointAtCenter={false} showArrow={true}>
                                <p style={{cursor:"pointer"}} onClick={() => props.onClickHeader(data?.actor?.userId)} className='adsTitle'>{data?.actor?.name}</p>
                            </Tooltip>
                            <p className='subTitle'>
                                <span>Create on: </span>{data.createdOn ? moment(data.createdOn).format('MMM DD, YYYY') : "-"}<br />
                                <span>Last seen on: </span>{data?.snapshot?.takenOn ? moment(data?.snapshot?.takenOn).format('MMM DD, YYYY') : "-"}
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
    const headerRight = () => {


        return (
            <div style={{ marginTop: "" }}>
                <Space direction="horizontal" style={{ width: '100%', marginTop: "-10px" }} >
                    <Button type='text' icon={<InfoCircleOutlined className='iconHeader' />}
                        onClick={() => {
                            localStorage.setItem('savedScrollPosition', data?.id);
                            history.push('/ads/' + data?.id);
                        }} />
                    <Button type='text' icon={<ExpandAltOutlined className='iconHeader' />} onClick={() => {
                        if(!data.linkToAd.includes("app.adspy.com"))
                            window.open(data.linkToAd, '_blank');
                        else {
                            localStorage.setItem('savedScrollPosition', data?.id);
                            history.push('/ads/' + data?.id);
                        }

                    }} />
                </Space>
            </div>
        )
    }
    const reaction = () => {
        return data.snapshot ?
            <div style={{ margin: "0 auto", textAlign: "left", width: "80%" }}>
                {data.snapshot?.likeNum && data.snapshot?.likeNum !== 0 ?
                    <Space direction="horizontal" style={{ justifyContent: "center", alignItems: "center", alignSelf: "center" }} >
                        <img className="emoticon" src="/assets/images/emoticons/like.png"></img>
                        <div style={{ color: "black" }}>{numberFormat(data.snapshot.likeNum)}</div>
                    </Space> : null}
                <div>
                    {data.snapshot?.loveNum && data.snapshot?.loveNum !== 0 ?
                        <Tooltip placement="bottom" title={numberFormat(data.snapshot.hahaNum)} showArrow={true}>
                            <img className="emoticon" src="/assets/images/emoticons/love.png"></img>
                        </Tooltip>
                        : null
                    }
                    {data.snapshot?.hahaNum && data.snapshot?.hahaNum !== 0 ?
                        <Tooltip placement="bottom" title={numberFormat(data.snapshot.hahaNum)} showArrow={true}>
                            <img className="emoticon" src="/assets/images/emoticons/haha.png"></img>
                        </Tooltip>
                        : null
                    }
                    {data.snapshot?.wowNum && data.snapshot?.wowNum !== 0 ?
                        <Tooltip placement="bottom" title={numberFormat(data.snapshot.wowNum)} showArrow={true}>
                            <img className="emoticon" src="/assets/images/emoticons/wow.png"></img>
                        </Tooltip>
                        : null
                    }
                    {data.snapshot?.sadNum && data.snapshot?.sadNum !== 0 ?
                        <Tooltip placement="bottom" title={numberFormat(data.snapshot.sadNum)} showArrow={true}>
                            <img className="emoticon" src="/assets/images/emoticons/sad.png"></img>
                        </Tooltip>
                        : null
                    }
                    {data.snapshot?.angryNum && data.snapshot?.angryNum !== 0 ?
                        <Tooltip placement="bottom" title={numberFormat(data.snapshot.angryNum)} showArrow={true}>
                            <img className="emoticon" src="/assets/images/emoticons/angry.png"></img>
                        </Tooltip>
                        : null
                    }

                </div>
            </div> : null



    }
    const shareComment = () => {
        return data.snapshot ? <div style={{ marginLeft: 10 }}>
            {!isNaN(data.snapshot?.commentsNum) ?
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span className="icon-bubble" style={{ color: "#424242" }}></span>
                    <div style={{ marginLeft: 6, color: "black", fontSize: 12 }}>{numberFormat(data.snapshot.commentsNum)} Comments</div>
                </div> : null}
            {!isNaN(data.snapshot?.shareNum) ?
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span className="icon-share2" style={{ color: "#424242" }} ></span>
                    <div style={{ marginLeft: 6, color: "black", fontSize: 12 }}>{numberFormat(data.snapshot.shareNum)} Shares</div>
                </div> : null}
        </div> : null
    }
    const maleFemale = () => {
        return <div >
            <div style={{ color: "black" }}>{data?.minAge ? data?.minAge == data?.maxAge ? data?.minAge : `${data?.minAge} - ${data?.maxAge}` : "-"}</div>
            {data?.genders?.length ?
                <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                    {data.genders.includes("Male") ?
                        <Tooltip placement="bottom" title={"Male"} showArrow={true}>
                            <span className="icon-man ng-star-inserted" style={{ color: "#337ab6" }}></span>
                        </Tooltip> : null
                    }
                    {data.genders.includes("Female") ?
                        <Tooltip placement="bottom" title={"Female"} showArrow={true}>
                            <span className="icon-woman ng-star-inserted" style={{ color: "#ff96f4" }}></span>
                        </Tooltip> : null
                    }

                </div> : null
            }

        </div>
    }

    const renderMedia = () => {
        let mainAttachment = data?.mainAttachment
        return <div>
            {data.adType == "Video" ?

                <div>
                    <div rel="noreferrer"
                        onClick={() => {
                            localStorage.setItem('savedScrollPosition', data?.id);
                            history.push('/ads/' + data?.id);
                        }}
                        className="ng-star-inserted">
                        <video controls preload="false" width="100%" poster={mainAttachment?.imageUrl} className="ng-star-inserted">
                            <source type="video/mp4" src={mainAttachment?.videoUrl} />
                        </video>
                    </div>
                    {props.isDetail ?
                        <a rel="noreferrer" target={"_blank"} href={mainAttachment?.videoUrl} className="ng-star-inserted">
                            <Button icon={<DownloadOutlined />} className='btnDark'>Download</Button>
                        </a> : null
                    }
                </div>
                : data.adType == "Photo" || data.adType == "" ?
                    <div rel="noreferrer"
                        onClick={() => {
                            localStorage.setItem('savedScrollPosition', data?.id);
                            history.push('/ads/' + data?.id);
                        }}
                        className="ng-star-inserted">
                        <div className="ng-star-inserted">
                            <img alt="" style={{ width: "100%" }} src={mainAttachment?.imageUrl} />
                        </div>
                    </div>

                    : null
            }
            {
                mainAttachment?.attachmentTitle ?
                    <div style={{ padding: '10px', border: '1px solid', borderColor: '#e9ebee #e9ebee #d1d1d1' }} className="ng-star-inserted">
                        <div rel="noreferrer"
                            onClick={() => {
                                localStorage.setItem('savedScrollPosition', data?.id);
                                history.push('/ads/' + data?.id);
                            }}
                        >
                            <div className="actionLinkLinkTitle">
                                {mainAttachment?.attachmentTitle}
                            </div>
                            <div className="actionLinkLinkDescription">{mainAttachment?.actionLinkLinkDescription}
                            </div>
                        </div>
                        <table style={{ width: '100%' }}>
                            <tbody><tr>
                                <td style={{ width: '70%', marginRight: '10px', whiteSpace: 'nowrap', overflow: 'hidden', color: '#90949c', fontSize: '11px', textTransform: 'uppercase', wordWrap: 'break-word' }}>
                                    {mainAttachment?.actionLinkLinkDisplay}
                                    <span className="ng-star-inserted">
                                    </span>
                                </td>
                                {
                                    mainAttachment?.actionLinkTitle ?
                                        <td style={{ width: '30%', textAlign: 'right' }} className="ng-star-inserted">
                                            <div rel="noreferrer"
                                                onClick={() => {
                                                    localStorage.setItem('savedScrollPosition', data?.id);
                                                    history.push('/ads/' + data?.id);
                                                }}
                                            >
                                                <Button className="btnDark">{mainAttachment?.actionLinkTitle}</Button>
                                            </div>
                                        </td>
                                        : null
                                }

                            </tr>
                            </tbody>
                        </table>
                    </div> : null
            }

        </div>


    }
    return (
        <Card
            id={data.id}
            title={headerLeft()}
            style={{
                margin: "10px 0px",
                ...props.style
            }}
            bodyStyle={{ padding: 10 }}
            headStyle={{ padding: 10 }}
            className={props.className}
            extra={headerRight()}
            actions={[
                reaction(),
                shareComment(),
                maleFemale(),
                <div style={{ color: "black", fontSize: 12 }}>{data.countries ? data.countries?.length > 2 ? `${data.countries?.length} countries` : data.countries.join(", ") : "-"}</div>,
            ]}
        >
            <p>{data?.text}</p>
            {renderMedia()}

        </Card>

    )
}
export default Item_Ads;