import React from 'react';
import ReactDOM from 'react-dom/client';
import { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { CloseOutlined, DownOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, Radio, Slider } from "antd";
import { uuidv4Short } from '../utils/help';
const Heading = ({ date, changeMonth, resetDate }) => (
  <nav className="calendar--nav">
    <a style={{zIndex:100}} onClick={() => changeMonth(date.month() - 1)}>&#8249;</a>
    <h1 style={{zIndex:90}} onClick={() => resetDate()}>{date.format('MMMM')} <small>{date.format('YYYY')}</small></h1>
    <a style={{zIndex:100}} onClick={() => changeMonth(date.month() + 1)}>&#8250;</a>
  </nav>
);

const Day = ({ currentDate, date, startDate, endDate, onClick }) => {
  let className = [];

  if (moment().isSame(date, 'day')) {
    className.push('active');
  }

  if (date.isSame(startDate, 'day')) {
    className.push('start');
  }

  if (date.isBetween(startDate, endDate, 'day')) {
    className.push('between');
  }

  if (date.isSame(endDate, 'day')) {
    className.push('end');
  }

  if (!date.isSame(currentDate, 'month')) {
    className.push('muted');
  }

  return (
    <span onClick={() => onClick(date)}  className={className.join(' ')}>{date.date()}</span>
  )
};

const Days = ({ date, startDate, endDate, onClick }) => {
  const thisDate = moment(date);
  const daysInMonth = moment(date).daysInMonth();
  const firstDayDate = moment(date).startOf('month');
  const previousMonth = moment(date).subtract(1, 'month');
  const previousMonthDays = previousMonth.daysInMonth();
  const nextsMonth = moment(date).add(1, 'month');
  let days = [];
  let labels = [];

  for (let i = 1; i <= 7; i++) {
    labels.push(<span key={uuidv4Short()} className="label">{moment().day(i).format('ddd')}</span>);
  }

  for (let i = firstDayDate.day(); i > 1; i--) {
    previousMonth.date(previousMonthDays - i + 2);

    days.push(
      <Day  key={uuidv4Short()} onClick={(date) => onClick(date)} currentDate={date} date={moment(previousMonth)} startDate={startDate} endDate={endDate} />
    );
  }

  for (let i = 1; i <= daysInMonth; i++) {
    thisDate.date(i);

    days.push(
      <Day key={uuidv4Short()} onClick={(date) => onClick(date)} currentDate={date} date={moment(thisDate)} startDate={startDate} endDate={endDate} />
    );
  }

  const daysCount = days.length;
  for (let i = 1; i <= (42 - daysCount); i++) {
    nextsMonth.date(i);
    days.push(
      <Day key={uuidv4Short()} onClick={(date) => onClick(date)} currentDate={date} date={moment(nextsMonth)} startDate={startDate} endDate={endDate} />
    );
  }

  return (
    <nav className="calendar--days">
      {labels.concat()}
      {days.concat()}
    </nav>
  );
};

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      startDate: props.value ? moment(props.value[0]) :null,
      endDate: props.value ? moment(props.value[1]):null,
    };
  }


  resetDate(status) {
    this.setState({
      date: moment(),
      startDate:moment(),
      endDate:moment(),
    });
    if(status) this.props.onReset();
  }

  changeMonth(month) {
    const { date } = this.state;

    date.month(month);

    this.setState(
      date
    );

  }

  changeDate(date) {
    let { startDate, endDate } = this.state;

    if (startDate === null || date.isBefore(startDate, 'day') || !startDate.isSame(endDate, 'day')) {
      startDate = moment(date);
      endDate = moment(date);
    } else if (date.isSame(startDate, 'day') && date.isSame(endDate, 'day')) {
      startDate = null;
      endDate = null;
    } else if (date.isAfter(startDate, 'day')) {
      endDate = moment(date);
    }

    this.setState({
      startDate,
      endDate
    });
    this.props.onChange(startDate, endDate);
  }

  render() {
    const { date, startDate, endDate } = this.state;
    // console.log(this.state.startDate.format("DD/MM"))
    return (
      <div>
        <div className="calendar">
          <Heading date={date} changeMonth={(month) => this.changeMonth(month)} resetDate={() => this.resetDate()} />

          <Days key={uuidv4Short()} onClick={(date) => this.changeDate(date)} date={date} startDate={startDate} endDate={endDate}  />
        </div>
        <div style={{ padding: 10, textAlign: "left", display: "flex", justifyContent: "space-between" }}>
          <Button onClick={() => this.resetDate(true)} className="btnDark" size="small" icon={<CloseOutlined />}>Reset</Button>
        </div>
      </div>
    );
  }
}
export default DatePicker