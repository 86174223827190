
import { IdcardOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Menu, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { setting_get } from '../../modules/setting/actions';

import Settings from "../Admin/Settings";
import Router from "./Router";

const App = (props) => {
    const dispatch = useDispatch();

    const { match, history, location } = props
    const [current, setCurrent] = useState("/admin/me");
    const _me = useSelector(({ user }) => user.me);
    const _user = _me.user
    useEffect(() => {
        if (location.pathname == "/admin") setCurrent("/admin/users")
        else {
            setCurrent(location.pathname)
        }
    }, [location.pathname])
    useEffect(() => {
        if (_user && _user?._id && _user?.role !== 0) {
            message.warning("Not have permission")
            history.push("/signin")
        }
       
    }, [_user])
    const [state, setState] = useState({
        isVisibleSetting: false
    })

    const items = [
       
        {
            label:
                <Link to="/admin/users">
                    <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>User Management</span>
                </Link>,
            key: '/admin/users',
            icon: <UserOutlined />,
        },
       
        {
            label: <Link to="/admin/accounts" >
                <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>Spy Accounts</span>
            </Link>,
            key: '/admin/accounts',
            icon: <IdcardOutlined />,
            style: { borderTop: "1px solid rgb(204 204 204 / 33%)" }
        },
       
        {
            label: <Link to="#" onClick={() => {
                setState({ ...state, isVisibleSetting: true })
            }} >
                <span style={{ color: isMobile ? "white" : "black", fontSize: 14, marginLeft: 8 }}>Settings</span>
            </Link>,
            key: '/admin/settings',
            icon: <SettingOutlined />,
            // style: {display: _user?.username == "admin" ? "block": "none"}
        },

    ];

    return (
        <>
            <Row style={{ width: "100%", marginTop:80, height: "100%" }} className="cpanel">
                <Col xl={4} lg={4} md={4} sm={4} xs={3} style={{ background: "white", borderRight: "1px solid rgb(241 241 241)" }}>
                    <Menu
                        selectedKeys={[current]}
                        mode="inline"
                        inlineCollapsed={isMobile}
                        className="menu_cpanel"
                        style={{
                            minHeight: 'calc(100vh - 180px)'
                            // height:"auto",
                            // background:"white"
                        }}
                        items={items}
                    />
                </Col>
                <Col Col xl={20} lg={20} md={20} sm={20} xs={21}>
                    <div style={{ background: "white", padding: 10, width: "100%", height: "100%" }}>
                        <Router match={match} location={location} history={history} />
                    </div>
                </Col>
            </Row>
            <Settings state={state} setState={setState} />
        </>

    )

};

export default App;
