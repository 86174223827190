import { httpClient } from "../../utils/Api";
import * as Types from "./constants";

const initState = {
  adsList: {
    msg: null,
    status: false,
    isLoading: false,
    data: [],
    current_page: 0,
  },
  adsDetail: {
    msg: null,
    status: false,
    isLoading: false,
    data: {},
  },
  saveList: {
    data: [],
    page: 0
  }
};
export default function adsReducer(state = initState, action = {}) {

  switch (action.type) {
    case Types.SAVE_GO_BACK:

      return {
        ...state,
        saveList: {
          data: [...action.payload.data],
          page: action.payload.page
        }
      };
    case Types.GET_ADS:
      return {
        ...state,
        adsList: {
          ...state.adsList,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_ADS_SUCCESS:
      try {
        if (action.payload.status) {
          state.adsList.data = action.payload.page == 0 ? [...action.payload.data] : [...state.adsList.data, ...action.payload.data]
        }

        return {
          ...state,
          
          adsList: {
            ...state.adsList,
            ...action.payload,
            data: state.adsList.data,
            isLoading: false,
          }
        };
      } catch (error) {
        return {
          ...state,
          adsList: {
            ...state.adsList,
            isLoading: false,
            msg: error.message
          }
        };
      }

    case Types.GET_ADS_ERROR:
      return {
        ...state,
        adsList: {
          ...state.adsList,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };

    /////////
    case Types.GET_ADS_DETAIL:
      return {
        ...state,
        adsDetail: {
          ...state.adsDetail,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.GET_ADS_DETAIL_SUCCESS:

      return {
        ...state,
        adsDetail: {
          ...state.adsDetail,
          data: action.payload.data,
          msg: action.payload.msg,
          status: action.payload.status,
          isLoading: false,

        }
      };
    case Types.GET_ADS_DETAIL_ERROR:
     
      return {
        ...state,
        adsDetail: {
          ...state.adsDetail,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };

    /////////
    case Types.RESET_ADS:
      let newState = {}
      if (action.payload.remove[0] == "all")
        newState = initState
      else
        for (let [key, value] of Object.entries(state)) {
          if (action.payload.remove.includes(key))
            newState[key] = { ...initState[key] }
          else newState[key] = value
        };
      return newState

    default:
      return state;
  }
}
