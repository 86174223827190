import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "./constants";
import * as services from "./services";

export function* get_ads(action) {
  try {
    const { data, status } = yield call(services.get_ads, action.payload);
    yield put({
      type: types.GET_ADS_SUCCESS,
      payload: data,
    });
    if(data.user)
    {
      yield put({
        type: "user/UPDATE_ME",
        payload: data.user,
      });
    }
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.GET_ADS_ERROR,
        payload: {msg:"Cannot connect to server", status:false},
      });
    }
    else
      yield put({
        type: types.GET_ADS_ERROR,
        payload: {
          msg: `Cannot connect to server`,
          status: false
        },
      });
  }
}
export function* get_ads_detail(action) {
  try {
    const { data, status } = yield call(services.get_ads_detail, action.payload);
    yield put({
      type: types.GET_ADS_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.GET_ADS_DETAIL_ERROR,
        payload: {msg:"Cannot connect to server", status:false},
      });
    }
    else
      yield put({
        type: types.GET_ADS_DETAIL_ERROR,
        payload: {
          msg: `Cannot connect to server`,
          status: false
        },
      });
  }
}

export default function* adsSaga() {
  yield takeLatest(types.GET_ADS, get_ads);
  yield takeLatest(types.GET_ADS_DETAIL, get_ads_detail);
 

}
