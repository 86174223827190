import React from "react";
import { Route, Switch } from "react-router-dom";
import Application from "./Application/Router";
import Admin from "./Admin";
import SignIn from "./SignIn";

const App = ({ match }) => {
  return (
    <Switch>

      <Route path={`${match.url}signin`} component={SignIn} />
      <Route path={`${match.url}admin`} component={Admin} />
      <Route path={`${match.url}`} component={Application} />
 
    </Switch>
  );
}



export default App;
