import { httpClient as request } from "../../utils/Api";
// router.post("/account/add_account", auth, accounts.add_account);
// router.post('/account/change_account_info', auth_valid, accounts.change_account_info)
// router.post("/account/update_account", auth, accounts.update_account);
// router.post("/account/add_account_random", auth, accounts.add_account_random);
// router.post("/account/delete_account", auth, accounts.delete_account);
// router.post("/account/get_accounts", auth, accounts.get_accounts);
// router.post("/account/get_accounts_by_admin", auth, accounts.get_accounts_by_admin);
// router.post("/account/get_accounts_owner", auth, accounts.get_accounts_owner);
// router.post("/account/buy_account", auth, accounts.buy_account);

export const add_account = payload => {
  return request.post("/account/create", payload)
};


export const update_account = payload => {
  return request.post("/account/update", payload) 
};

export const delete_account = payload => {
  return request.post("/account/delete", payload)
};

export const get_accounts = payload => {
  return request.post("/account/get_accounts", payload)
};


export const get_account_info = payload => {
  return request.post("/account/check_info", payload)
};