import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/App.css';
import './assets/Icon.css';
import './assets/New.css';
import NextApp from './NextApp';
class Doc extends React.PureComponent {
  render(){
    return(
      <div>
        <NextApp /> 
      </div>
    )
  }
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Doc />
);

