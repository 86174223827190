import { CalculatorOutlined, CalendarOutlined, GlobalOutlined, KeyOutlined, LogoutOutlined, MailOutlined, PhoneOutlined, SendOutlined, TagOutlined, UserOutlined } from "@ant-design/icons";
import { Badge, Button, Col, DatePicker, Input, Row, Select, Space } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import 'moment/locale/vi';
import React from "react";
import { NumericInput } from "../../../utils/help";
const { Option } = Select;

const UserView = (props) => {
   let state = props.state
   let isMobile = props.isMobile
   let _update = props._update
   let _create = props._create
   let userSelect = state.userSelect
   return (
      <Modal title={userSelect._id !== null ? `Edit User` : "Add User"}
         open={state.isVisibleUser}

         destroyOnClose={true}
         onCancel={() => props.setState({ ...state, isVisibleUser: false })}
         width={isMobile ? "90%" : "700px"}
         footer={<Space direction="horizontal" style={{ width: '100%', justifyContent: isMobile ? "center" : "right" }} className="gx-w-100">
            <Button
               className='color1'
               loading={userSelect._id !== null ? _update.isLoading : _create.isLoading}
               onClick={() => {
                  if (userSelect._id !== null) {
                     props.handleUpdate()
                  }
                  else {
                     props.handleCreate()
                  }
               }} type="primary" icon={<SendOutlined />}>{userSelect._id !== null ? "Update" : "Create"}</Button>
            <Button icon={<LogoutOutlined />} onClick={() => props.setState({ ...state, isVisibleUser: false })} type="primary" className='color2'>Close</Button>
         </Space>}
      >

         {userSelect._id !== null ?
            <Row >

               <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                  <Input size="middle" placeholder="Created At" prefix={<CalendarOutlined />}
                     value={moment(userSelect.createdAt).format("DD/MM/YYYY HH:mm")}
                     disabled
                     addonAfter="Created At"
                  />
               </Col>
               <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                  <Input size="middle" placeholder="Update At" prefix={<CalendarOutlined />}
                     value={moment(userSelect.updatedAt).format("DD/MM/YYYY HH:mm")}
                     addonAfter="Update At"
                     disabled />
               </Col>

            </Row> : null}
         {userSelect._id !== null ?
            <Row >
               <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                  <Input size="middle" placeholder="Địa chỉ IP" prefix={<GlobalOutlined />}
                     value={userSelect?.ip_web}
                     addonAfter="IP"
                     disabled
                     maxLength={10}
                  />

               </Col>
               <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                  <Input size="middle" placeholder="Last Login" prefix={<CalendarOutlined />}
                     value={userSelect.last_login ? moment(userSelect.last_login).format("DD/MM/YYYY HH:mm"): "Unknown"}
                     disabled
                     addonAfter="Last Login"
                  />
               </Col>
            </Row> : null}
         <Row >

           <Col xl={12} lg={12} md={24} sm={24} xs={24} className={!isMobile ? "pdR" : "pdB"}>
               <Input size="middle" placeholder="Username" prefix={<UserOutlined />}
                  value={userSelect.username}
                  addonAfter="Username"
                  disabled={userSelect._id == null ? false : true}
                  onChange={(e)=> props.setState({...state, userSelect:{...userSelect, username: e.target.value}})}
               />
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <Input.Password size="middle" placeholder="Leave if you don't change" prefix={<KeyOutlined />}
                  value={userSelect.password}
                  addonAfter="Password"
                  onChange={(e) => props.setState({ ...state, userSelect: { ...userSelect, password: e.target.value } })}
               />
            </Col>
         </Row>


         <Row >
            <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <NumericInput size="middle" placeholder="Views Left" prefix={<TagOutlined />}
                  value={userSelect.viewsLeft}
                  addonAfter="Views Left"
                  onChange={(e) => props.setState({ ...state, userSelect: { ...userSelect, viewsLeft: e.target.value } })}
                  maxLength={10}
               />
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <NumericInput size="middle" placeholder="Views Period" prefix={<CalculatorOutlined />}
                  value={userSelect.viewsCurrentPeriod}
                  addonAfter="Views Period"
                  disabled
                  onChange={(e) => props.setState({ ...state, userSelect: { ...userSelect, viewsCurrentPeriod: e.target.value } })}
               />
            </Col>
         </Row>


         <Row >
            <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <p style={{ fontSize: 12, marginBottom: 2 }}>Role</p>
               <Select
                  placeholder="Role"
                  defaultValue={userSelect.role}
                  value={userSelect.role}
                  style={{ width: "100%" }} onChange={(value) => {
                     props.setState({ ...state, userSelect: { ...userSelect, role: value } })
                  }}
               >
                  <Option value={0}>Admin</Option>
                  <Option value={1}>User</Option>

               </Select>
            </Col>
            <Col xl={6} lg={6} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <p style={{ fontSize: 12, marginBottom: 2 }}>Auto Renew</p>
               <Select
                  placeholder="Auto Renew"
                  defaultValue={userSelect.auto_renew}
                  value={userSelect.auto_renew}
                  style={{ width: "100%" }} onChange={(value) => {
                     props.setState({ ...state, userSelect: { ...userSelect, auto_renew: value } })
                  }}
               >
                  <Option value={true}><Badge color={"green"} size="20" style={{ marginRight: 10 }}></Badge>Yes</Option>
                  <Option value={false}><Badge color={"red"} size="20" style={{ marginRight: 10 }}></Badge>No</Option>


               </Select>
            </Col>

            <Col xl={6} lg={6} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <p style={{ fontSize: 12, marginBottom: 2 }}>Expired</p>
               <DatePicker placeholder="Expiry Date" style={{ width: "100%" }} format={"DD/MM/YYYY"} value={userSelect.expiry_date ? moment(userSelect.expiry_date) : null}
                  onChange={(value) => {
                     props.setState({ ...state, userSelect: { ...userSelect, expiry_date: value } })
                  }} />

            </Col>
         </Row>
         <Row>
            <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <p style={{ fontSize: 12, marginBottom: 2 }}>User Type</p>
               <Select
                  placeholder="User Type"
                  defaultValue={userSelect.user_type}
                  value={userSelect.user_type}
                  style={{ width: "100%" }} onChange={(value) => {
                     props.setState({ ...state, userSelect: { ...userSelect, user_type: value } })
                  }}
               >
                  <Option value={1}><Badge color={"green"} size="20" style={{ marginRight: 10 }}></Badge>Premium</Option>
                  <Option value={0}><Badge color={"blue"} size="20" style={{ marginRight: 10 }}></Badge>Free</Option>


               </Select>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <p style={{ fontSize: 12, marginBottom: 2 }}>Status</p>
               <Select
                  defaultValue={userSelect.status}
                  value={userSelect.status}
                  style={{ width: "100%" }} onChange={(value) => props.setState({ ...state, userSelect: { ...userSelect, status: value } })}
               >
                  <Option value={false}><Badge color={"red"} size="20" style={{ marginRight: 10 }}></Badge>Deactive</Option>
                  <Option value={true}><Badge color={"green"} size="20" style={{ marginRight: 10 }}></Badge>Active</Option>

               </Select>
            </Col>
         </Row>
      </Modal>

   )
}
export default UserView