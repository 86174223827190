import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Space } from "antd";
import 'moment/locale/vi';
import { useState } from "react";
import { isMobile } from 'react-device-detect';
import { uuidv4Short } from "../../../utils/help";


const Modal_Filter = (props)=>{
    const [saveName, setSaveName] = useState(null);
    return (<Modal title={props.title} open={props.open}

    bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
    destroyOnClose={true}
    maskClosable={true}
    onCancel={props.onCancel}
    width={isMobile ? "90%" : props.size || "600px"}
    footer={props.footer == false ? null : <Space direction="horizontal" style={{ width: '100%', justifyContent: isMobile ? "center" : "right" }} >
    <Button
       icon={<CheckOutlined />}
       loading={false}
       onClick={()=>props.onOK(saveName)}
       className="btnDark"
       type="primary" >{"Save"}</Button>
    <Button icon={<CloseOutlined />} onClick={props.onCancel} type="primary" className='btnDark'>Cancel</Button>
 </Space>}
    >
  
       
      {props.content}
  
    </Modal>)
}
export default Modal_Filter