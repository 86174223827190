import { LogoutOutlined, SaveOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Input, message, Modal, Row, Select, Space } from "antd";
import 'moment/locale/vi';
import { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux";

import { setting_get, setting_set } from "../../../modules/setting/actions";

const { Option } = Select;

const Settings = (props) => {
   let { isVisibleSetting } = props.state
   const dispatch = useDispatch();
   const _settingList = useSelector(({ setting }) => setting.settingList);
   const _settingSet = useSelector(({ setting }) => setting.settingSet);

   let initialState = {
      configs: null,
   }
   const [state, setState] = useState(initialState)

   // useEffect(() => {
   //    dispatch(setting_get())

   // }, [isVisibleSetting])
   useEffect(() => {
      if (_settingList.status) {

         let configs = _settingList.data.length && _settingList.data.find(item => item.key == "configs")

         setState({
            ...state,
            configs: { list: configs.value, description: configs.description, index: _settingList.data.indexOf(configs) },
         })
      }
      else if (_settingList.status == false && _settingList.msg !== null) {
         message.warning("No found setting configs")
      }
   }, [_settingList])

   useEffect(() => {
      if (_settingSet.status) {
         message.success(_settingSet.msg)
      }
      else if (_settingSet.status == false && _settingSet.msg !== null) {
         message.warning(_settingSet.msg)
      }
   }, [_settingSet])



   const handleSave = () => {
      let configs_result = null
      Object.keys(state.configs?.list).map((key, index) => {
         let item = state.configs.list[key]
         if (typeof item == "object")
            if (!item.message || item.message == "" || ![true, false].includes(item.status)) {
               configs_result = key
               return
            }
            else if (item == "") {
               configs_result = key
               return
            }
      })

      if (configs_result !== null)
         return message.warning(`Setting ${configs_result} wrong config`)
         let newSettingList = _settingList.data
         newSettingList[state.configs.index].value = state.configs.list
       
      dispatch(setting_set({
         settings: newSettingList
      }))
   }
   console.log(state.configs)
   return (
      <Modal title={"Settings"}
         open={isVisibleSetting}

         bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
         destroyOnClose={true}
         maskClosable={false}
         onCancel={() => props.setState({ ...state, isVisibleSetting: false })}
         width={isMobile ? "90%" : "500px"}
         footer={<Space direction="horizontal" style={{ width: '100%', justifyContent: isMobile ? "center" : "right" }} className="gx-w-100">
            <Button
               className='color1'
               loading={_settingSet.isLoading}
               onClick={() => {
                  handleSave()
               }}
               type="primary" icon={<SaveOutlined />}>{"Save"}</Button>
            <Button icon={<LogoutOutlined />} onClick={() => props.setState({ ...state, isVisibleSetting: false })} type="primary" className='color2'>Close</Button>
         </Space>}
      >
         {
            state.configs ? <>
               <Row>
                  <Col xl={24} lg={24} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                  <p style={{marginBottom:0}}>Message</p>
                     <Input.TextArea
                        size="5"
                        value={state.configs.list.alert.message} addonAfter={<div style={{ width: 80 }}>Message</div>}
                        onChange={(e) => {
                           setState({ ...state, configs: { ...state.configs, list: { ...state.configs.list, alert: { ...state.configs.list.alert, message: e.target.value } } } })
                        }}
                     />
                  </Col>
                  <Col xl={24} lg={24} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                     <p style={{marginBottom:0}}>Status</p>
                     <Select
                        placeholder="Status"
                        value={state.configs.list.alert.status}
                        style={{ width: "100%" }}
                        onChange={(value) => {

                           setState({ ...state, configs: { ...state.configs, list: { ...state.configs.list, alert: { ...state.configs.list.alert, status: value } } } })
                        }}
                     >
                        <Option value={true}><Badge color={"green"} size="20" text="Active"></Badge></Option>
                        <Option value={false}><Badge color={"red"} size="20" text="Deactive"></Badge></Option>

                     </Select>
                  </Col>
               </Row>


            </> : null
         }

      </Modal>


   )
}
export default Settings