import { connectRouter } from 'connected-react-router';
import { combineReducers } from "redux";
import accountReducer from "./modules/account/reducer";
import adsReducer from './modules/ads/reducer';
import settingReducer from "./modules/setting/reducer";
import userReducer from "./modules/user/reducer";



const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  user:  userReducer,
  account: accountReducer,
  setting: settingReducer,
  ads: adsReducer
});

export default createRootReducer;
