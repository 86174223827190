import { CheckCircleOutlined, DeleteOutlined, EyeOutlined, ReloadOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Card, Input, message, Pagination, Select, Space, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import 'moment/locale/vi';
import { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from 'use-debounce';
import { numberFormat } from "../../../utils/help";
import AccountView from "./AccountView";
import SweetAlert from "react-bootstrap-sweetalert";
import { add_account, delete_account, get_accounts, reset_account, update_account, get_account_info } from "../../../modules/account/actions";

const { Option } = Select;
const { TextArea } = Input;


const Accounts = (props) => {

   const dispatch = useDispatch();
   const _accountList = useSelector(({ account }) => account.accountList);
   const _updateAccount = useSelector(({ account }) => account.updateAccount);
   const _addAccount = useSelector(({ account }) => account.addAccount);
   const _deleteAccount = useSelector(({ account }) => account.deleteAccount);
   const _accountInfo = useSelector(({ account }) => account.accountInfo);

   let initialState = {
      page: 0,
      search: null,
      account_type: null,
      status: null,
      select: null,
      log: null,
      userLog: null,
      isVisibleConfirmDelete: false,
      isVisibleModalRandom: false,
      isVisibleModal: false,
   }
   const [state, setState] = useState(initialState)
   const [search] = useDebounce(state.search, 1000);

   const handleGetAccount = async () => {
      dispatch(get_accounts({
         status: state.status,
         account_type: state.account_type,
         search: state.search,
         page: state.page
      }))
   }

   const handleUpdateLog = async () => {
      if (state.log !== null) {
         let payload = {
            ...state.userLog,
            log: state.log
         }
         dispatch(update_account(payload))
      }
   }
   useEffect(() => {
      if (state.account_type !== null) {
         handleGetAccount()
      }
   }, [state.account_type])


   useEffect(() => {
      if (state.search !== null) {
         handleGetAccount()
      }
   }, [search])
   useEffect(() => {
      if (state.status !== null) {
         handleGetAccount()
      }
   }, [state.status])
   useEffect(() => {
      window.scrollTo(0, 0)
      handleGetAccount()
      return () => {
         dispatch(reset_account({ remove: ["all"] }))
      };
   }, [])
   const deleteConfirm = () => {
      setState({ ...state, isVisibleConfirmDelete: false })
      dispatch(delete_account({
         "_id": state.select._id,
      }))
   }
   const handleShowModal = (record) => {
      if (record) {
         setState({
            ...state, select: {
               ...record,
            }, isVisibleModal: true
         })
      }
      else {
         setState({
            ...state,
            select: {
               _id: null,
               status: true,
               live: true, 
               account_type: 0
            },
            isVisibleModal: true
         })
      }

   }

   const handleUpdateAccount = () => {
      let payload = {
         ...state.select
      }
      dispatch(update_account(payload))
   }
   const handleAddAccount = () => {
      let payload = {
         ...state.select,
      }
      dispatch(add_account(payload))
   }

   useEffect(() => {
      if (_updateAccount.status) {
         message.success(_updateAccount.msg)
         setState({ ...state, log: null, userLog: null })
      }
      else if (!_updateAccount.status && _updateAccount.msg !== null) message.warning(_updateAccount.msg)
   }, [_updateAccount])

   useEffect(() => {
      if (_deleteAccount.status) message.success(_deleteAccount.msg)
      else if (!_deleteAccount.status && _deleteAccount.msg !== null) message.warning(_deleteAccount.msg)
   }, [_deleteAccount])
   useEffect(() => {
      if (_accountInfo.status) message.success(_accountInfo.msg)
      else if (!_accountInfo.status && _accountInfo.msg !== null) message.warning(_accountInfo.msg)
   }, [_accountInfo])

   useEffect(() => {
      if (_addAccount.status) {
         message.success(_addAccount.msg)
         setState(state=>({...state, isVisibleModal: false}))
      }
      else if (!_addAccount.status && _addAccount.msg !== null) message.warning(_addAccount.msg)
   }, [_addAccount])

   let columns = [
      {
         title: 'Create Date',
         key: 'createdAt',
         dataIndex: 'createdAt',
         width: 100,
         render: (text) => <>
            <span>{moment(text).format("DD/MM/YYYY")}</span><br />
            <span>{moment(text).format("HH:mm")}</span>
         </>
      },
     
      {
         title: 'Account',
         dataIndex: 'email',
         key: 'email',
         width: 300,
         ellipsis: true,
         render: (text, record) => {
            return <>
               <Space>
                  <Avatar size="default" src={"https://cdn-icons-png.flaticon.com/512/2314/2314909.png"} />
                  <div style={{ alignItems: "center" }}>
                     <span>
                        {record.live ? <Badge color={"green"} text={"LIVE"} /> : <Badge color={"red"} text={"DIE"} />}
                     </span>
                     <br />
                     <span>
                        {text}
                     </span>
                  </div>
               </Space>
            </>
         }
      },
      {
         title: 'Type',
         key: 'account_type',
         dataIndex: 'account_type',
         width: 90,
         align: "center",
         render: (text) => <Tag style={{ borderRadius: 10 }} color={text == 0 ? "orange" : "green"}>{text == 0 ? "FREE" : "PREMIUM"}</Tag>
      },
      {
         title: "View Limit",
         key: 'viewsLimit',
         dataIndex: 'viewsLimit',
         width: 100,
         align: "right",
         render: (text) => numberFormat(text)
      },
      {
         title: "View Left",
         key: 'viewsLeft',
         dataIndex: 'viewsLeft',
         width: 100,
         align: "right",
         render: (text) => numberFormat(text)
      },
      {
         title: "Views Period",
         key: 'viewsCurrentPeriod',
         dataIndex: 'viewsCurrentPeriod',
         width: 100,
         align: "right",
         render: (text) => numberFormat(text)
      },
      {
         title: "Log",
         key: 'log',
         dataIndex: 'log',
         width: 150,
         render: (text, record) => <div style={{ display: "flex", flexDirection: "row" }}>
            <TextArea style={{ height: "60px" }}
               onClick={() => {
                  setState({ ...state, userLog: record })
               }}
               onChange={(e) => {
                  setState({ ...state, log: e.target.value })
               }}
               rows={2} value={state.userLog && state.userLog._id == record._id ? state.log : ""} ></TextArea>
            <Button onClick={() => handleUpdateLog()} style={{ height: "60px", width: "35px", marginLeft: 5 }} className="green" icon={<CheckCircleOutlined />}></Button>
         </div>
      },
      {
         title: 'Outdated',
         key: 'isOutdated',
         dataIndex: 'isOutdated',
         width: 100,
         align: "center",
         render: (text) => !text ? <Badge color={"green"} text="NO" /> : <Badge color={"red"} text="YES" />
      },
      {
         title: 'Suppend',
         key: 'isSuspended',
         dataIndex: 'isSuspended',
         width: 100,
         align: "center",
         render: (text) => !text ? <Badge color={"green"} text="NO" /> : <Badge color={"red"} text="YES" />
      },
      {
         title: 'Status',
         key: 'status',
         dataIndex: 'status',
         width: 100,
         align: "center",
         render: (text) => text ? <Badge color={"green"} text="Active" /> : <Badge color={"red"} text="Deactive" />
      },
      {
         title: 'Menu',
         width: 100,
         align: "center",
         // fixed: 'right',
         render: (text, record) => (

            <Space>
               <Tooltip placement="bottom" title={"Edit Account"}>
                  <Button
                     type="primary"
                     size="small"
                     icon={<EyeOutlined />}

                     onClick={() => {
                        handleShowModal(record)
                     }}
                  ></Button>
               </Tooltip>
               <Tooltip placement="bottom" title={"Delete Account"}>
                  <Button
                     type="primary"
                     size="small"
                     danger
                     icon={<DeleteOutlined />}

                     onClick={() => {
                        setState({ ...state, select: record, isVisibleConfirmDelete: true })
                     }}
                  ></Button>
               </Tooltip>
               <Tooltip placement="bottom" title={"Check Live"}>
                  <Button
                     type="primary"
                     size="small"
                     style={{background:"limegreen", border: "none"}}
                     icon={<ReloadOutlined />}
                     loading={state.select?._id == record._id && _accountInfo.isLoading}
                     onClick={() => {
                        setState({...state, select: record})
                        dispatch(get_account_info({_id: record._id}))
                     }}
                  ></Button>
               </Tooltip>
            </Space >

         ),
      },
   ];
   let columns_mobile = [
      {
         title: 'Account',
         dataIndex: 'email',
         key: 'email',
         width: 300,
         ellipsis: true,
         render: (text, record) => {
            return <>
               <Space>
                  <Avatar size="default" src={"https://cdn-icons-png.flaticon.com/512/2314/2314909.png"} />
                  <div style={{ alignItems: "center" }}>
                     <span>
                        {record.live ? <Badge color={"green"} text={"LIVE"} /> : <Badge color={"red"} text={"DIE"} />}
                     </span>
                     <br />
                     <span>
                        {text}
                     </span>
                  </div>
               </Space>
            </>
         }
      },
      {
         title: 'Menu',
         width: 80,
         align: "center",
         // fixed: 'right',
         render: (text, record) => (
            <Space>
               <Tooltip placement="bottom" title={"Edit Account"}>
                  <Button
                     type="primary"
                     size="small"
                     icon={<EyeOutlined />}

                     onClick={() => {
                        handleShowModal(record)
                     }}
                  ></Button>
               </Tooltip>
               <Tooltip placement="bottom" title={"Delete Account"}>
                  <Button
                     type="primary"
                     size="small"
                     danger
                     icon={<DeleteOutlined />}

                     onClick={() => {

                       
                           setState({ ...state, select: record, isVisibleConfirmDelete: true })
                      

                     }}
                  ></Button>
               </Tooltip>
            </Space >
         ),
      },
   ];
   const optionTable = {
      bordered: true,
      loading: _accountList.isLoading,
      size: 'small',
      showHeader: true,
      columns: isMobile ? columns_mobile : columns,
      dataSource: [..._accountList.data],
      pagination: { position: ['none', 'none'], defaultPageSize: _accountList.per_page },
      scroll: isMobile ? {} : { y: "calc(100vh - 300px)", x: 1000 },
      expandable: !isMobile ? {
         expandedRowRender: (record) => (
            <>
               {

                  record.log.map((log) => <p
                     style={{
                        margin: 0,
                     }}
                  >
                     {log}
                  </p>)}
            </>
         ),
         rowExpandable: (record) => record?.log?.length !== 0 && !isMobile,
      } : null
   };

   const changePage = (page) => {
      setState({ ...state, page: page })

   }
   return (

      <Card
         bodyStyle={{ padding: 10 }}
         headStyle={{ padding: 10 }}
         size="small"
         title={"Spy Account Management"}
         extra={
            <div>
               <Input type="search"
                  allowClear={true}
                  style={{ width: isMobile ? "100%" : "200px", marginBottom: 10, }}
                  placeholder="Find by Email"
                  onChange={(e) => setState({ ...state, search: e.target.value })}
                  value={state.search}
               ></Input>
               <Select
                  defaultValue={state.account_type}
                  value={state.account_type}
                  allowClear={true}
                  placeholder={"Account type"}
                  style={{ width: isMobile ? "100%" : "200px", marginBottom: 10, marginLeft: !isMobile ? 10 : 0 }}
                  onChange={(value) => {
                     setState({ ...state, account_type: value })
                  }}
               >
                  <Option value={0}><Badge color={"blue"} size="20" style={{ marginRight: 10 }}></Badge>Free</Option>
                  <Option value={1}><Badge color={"green"} size="20" style={{ marginRight: 10 }}></Badge>Premium</Option>
                
               </Select>
               <Select
                  defaultValue={state.status}
                  value={state.status}
                  allowClear={true}
                  placeholder={"Status filter"}
                  style={{ width: isMobile ? "100%" : "200px", marginBottom: 10, marginLeft: !isMobile ? 10 : 0 }}
                  onChange={(value) => {
                     setState({ ...state, status: value })
                  }}
               >
                  <Option value={true}><Badge color={"green"} size="20" style={{ marginRight: 10 }}></Badge>Active</Option>
                  <Option value={false}><Badge color={"red"} size="20" style={{ marginRight: 10 }}></Badge>Deactive</Option>
                
               </Select>
               
                  <Button type="primary" style={{ width: isMobile ? "100%" : "150px", marginLeft: !isMobile ? 10 : 0, marginTop: 10 }} className="color1"
                     onClick={()=>{
                        handleShowModal(null)
                     }}
                  >
                     Add Account
                  </Button>
             

            </div>

         }
         style={{ width: "100%" }}>

         <Table
            {...optionTable}
            rowKey={(record) => record._id}
 
         />
         <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
            <Pagination current={_accountList.current_page + 1} total={_accountList.total} pageSize={_accountList.per_page} onChange={changePage} style={{ paddingTop: 10 }} showSizeChanger={false} />
         </Space>

         {state.select ?
            <AccountView

               update={_updateAccount}
               create={_addAccount}
               isMobile={isMobile}
               state={state}
               setState={setState}
               handleUpdate={handleUpdateAccount}
               handleCreate={handleAddAccount}
            /> : null}

         <SweetAlert show={state.isVisibleConfirmDelete}
            warning
            showCancel
            confirmBtnText={"Oke. Delete"}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={`Are you you confirm delete this account?`}
            onConfirm={deleteConfirm}
            onCancel={() => setState({ ...state, isVisibleConfirmDelete: false })}
         ></SweetAlert>
      </Card>


   )
}
export default Accounts